const newMessageReducer = (state = [], action) => {

    switch (action.type) {
        case 'NEW_MESSAGE':
            return action.data
        default:
            return state
    }
}

export default newMessageReducer;
