/* eslint-disable no-template-curly-in-string */
import React, {useEffect, useState} from 'react';
import TemplateHeader from "./templateHeader";
import TemplateListComponent from "./list";

const TemplatesComponent = () => {
    const [updateTemplateList, setUpdateTemplateList] = useState('')
    useEffect(() => {
        return () => {
        }
    }, [])

    const handleCreateTemplate = value => {
        setUpdateTemplateList(value)
    }

    return (
        <div className="global-wrapper-templates">
            <TemplateHeader handleCreateTemplate={handleCreateTemplate}/>
            <TemplateListComponent updateList={updateTemplateList}/>
        </div>
    )
}

export default TemplatesComponent;
