import React, {useEffect} from 'react';
import "./Assets/css/style.css";
import {useSelector} from 'react-redux'
import {Router, Route, Switch, Redirect} from "react-router-dom";
import {
    authHeader,
    history,
    signOut,
    getLocalStorage,
    internetStatus,
} from "./_helpers";
import axios from "axios";
import MainWrapper from './mainWrapper'
import Login from "./components/login/index";
import createAuthRefreshInterceptor from "axios-auth-refresh";

const apiUrl = process.env.REACT_APP_API;

const App = () => {
    const isLogged = useSelector(globalState => globalState.isLogged)
    const user = getLocalStorage("user", true)
    const refreshAuthLogic = () => {
        return signOut();
    }
    createAuthRefreshInterceptor(axios, refreshAuthLogic);

    useEffect(() => {
        internetStatus()
        if ((user != null && (user.token !== undefined || user.token !== '')) && !isLogged) {
            checkUser().then(payload => {
                if (payload !== 200) {
                    return signOut();
                }
            })
        }
    }, [
        user,
        isLogged,
    ]);

    const checkUser = async () => {
        let res = await axios.get(`${apiUrl}/adminusers/check-token`, {
            headers: authHeader()
        });

        let {data} = res;
        if (data.error === null) {
            return 200
        } else {
            return data.error.code
        }
    }

    return (
        <>
            {isLogged || user ?
                <Router history={history}>
                    <MainWrapper/>
                </Router>
                :
                <Router history={history}>
                    <Switch>
                        <Route
                            exact
                            path="/login"
                            component={Login}
                        />
                        <Redirect to={{pathname: "/login"}}/>
                    </Switch>
                </Router>
            }
        </>
    );
}

export default App;
