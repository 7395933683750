/* eslint-disable no-template-curly-in-string */
import React, {useEffect, useState} from 'react';
import Tabs from 'antd/lib/tabs';
import TemplateButtonList from "./buttonList";
import TemplateImageList from "./imageList";
import TemplateTextList from "./textList";

const {TabPane} = Tabs;
const TemplateListComponent = (props) => {
    const [state, setState] = useState({
        tabKey: 'text',
        updateList: props.updateList
    })


    useEffect(() => {
        if (document.getElementsByClassName('ant-tabs-ink-bar')[0]) {
            document.getElementsByClassName('ant-tabs-ink-bar')[0].remove()
        }
        return () => {
        }
    })

    useEffect(() => {
        if (props.updateList && props.updateList !== state.updateList) {
            setState(prevState => {
                return {
                    ...prevState,
                    updateList: props.updateList
                }
            })
        }
        return () => {
        }
    }, [props.updateList, state.updateList])

    const tabChange = async key => {
        setState(prevState => {
            return {
                ...prevState,
                tabKey: key
            }
        })
    }
    const {tabKey, updateList} = state

    return (
        <div className="template-box-content">
            <Tabs activeKey={tabKey} destroyInactiveTabPane={true} defaultActiveKey={tabKey} animated={false}
                  onChange={tabChange}>
                <TabPane forceRender={false} className="pos-relative" tab="Text" key="text">
                    <TemplateTextList updateList={updateList}/>
                </TabPane>

                <TabPane forceRender={false} className="pos-relative" tab="App Links" key="button">
                    <TemplateButtonList updateList={updateList}/>
                </TabPane>

                <TabPane forceRender={false} className="pos-relative" tab="Images" key="image">
                    <TemplateImageList updateList={updateList}/>
                </TabPane>
            </Tabs>
        </div>
    )
}

export default TemplateListComponent;
