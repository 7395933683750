/* eslint-disable no-template-curly-in-string */
const threadRemovedReducer = (state = [], action) => {

    switch (action.type) {
        case 'REMOVE_THREAD':
            return action.data
        default:
            return state
    }
}

export default threadRemovedReducer;
