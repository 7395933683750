import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux'
import allReducers from './reducers';
import {createStore} from 'redux';
import App from './App';
import reportWebVitals from './reportWebVitals';

const globalStore = createStore(
    allReducers
);
ReactDOM.render(
    <Provider store={globalStore}>
        <App/>
    </Provider>,
    document.getElementById('support-chat-container')
);

reportWebVitals();
