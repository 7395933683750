/* eslint-disable no-template-curly-in-string */
import React, {useEffect, useState} from 'react';
import {errorMessage} from "../../../_helpers";
import Image from 'antd/lib/image'
import {ReactComponent as UploadIcon} from "../../../Assets/svg/icon-upload.svg";

const TemplateTypeImage = (props) => {
    let {error} = props

    const [state, setState] = useState({
        uploaderLoader: false,
        image: '',
        file: '',
        showError: false
    })

    useEffect(() => {
        if (error) {
            setState(prevState => {
                return {
                    ...prevState,
                    showError: true
                }
            })
        }
        return () => {
        }
    }, [error])

    const getBase64 = (img, callback) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    }

    const handleFileChange = async e => {
        let filesArr = e.target.files
        let file = filesArr[0]

        setState(prevState => {
            return {
                ...prevState,
                showError: false,
                uploaderLoader: true
            }
        })

        // const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';

        const isJpgOrPng = (/(gif|jpe?g|tiff?|png|webp|bmp)$/i).test(file.type)

        if (!isJpgOrPng) {
            setState(prevState => {
                return {
                    ...prevState,
                    uploaderLoader: false
                }
            })
            await errorMessage('You can only upload JPG/PNG file!')
            return false
        }

        getBase64(file, imageUrl => {
                setState(prevState => {
                    return {
                        ...prevState,
                        uploaderLoader: false,
                        image: imageUrl,
                        file
                    }
                })

                props.handleData({image: file})
            }
        );
    };

    const {image, showError} = state

    return (
        <div className={`attach-box ${showError ? "ant-form-item-has-error" : ''}`}>
            <input className="hidden" type="file" id="templateTile"
                   value={''} onChange={handleFileChange}/>
            {image ? (
                <div className="attached-file" onClick={() => {
                    setState(prevState => {
                        return {
                            ...prevState,
                            image: '',
                            file: ''
                        }
                    })
                    props.handleData('')
                }}>
                    <Image preview={false} src={image} alt="avatar" style={{width: '100%'}}/>
                </div>
            ) : (
                <>
                    <label htmlFor="templateTile" className={showError ? 'error' : ''}>
                        <div className="attach-icon">
                            <UploadIcon/>
                            <div className="text">
                                Upload Image
                            </div>
                        </div>
                    </label>
                    {showError && (
                        <div className="ant-form-item-explain ant-form-item-explain-error">
                            <div role="alert">Image is required!</div>
                        </div>
                    )}
                </>
            )}
        </div>
    )
}

export default TemplateTypeImage;
