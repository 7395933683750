/* eslint-disable no-template-curly-in-string */
import React, {useEffect, useState} from "react";
import Modal from "antd/lib/modal";
import Spin from "antd/lib/spin";
import Input from 'antd/lib/input';
import {authHeader, errorMessage} from "../../_helpers";
import axios from "axios";
import InfiniteScroll from "react-infinite-scroll-component";

const {Search} = Input;
const apiUrl = process.env.REACT_APP_API;

const RecipientsList = (props) => {
    const {visible, messageId} = props
    const [state, setState] = useState({
        userHasMore: true,
        listTotal: 0,
        loader: false,
        showModal: false,
        userList: {},
        pp: 30,
        searchValue: '',
    })

    useEffect(() => {
        if (visible) {
            setState(prevState => {
                return {
                    ...prevState,
                    showModal: true,
                    loader: true
                }
            })
            getUserList(messageId).then(res => {
                const {message, code, payload} = res
                if (code === 200) {
                    setState(prevState => {
                        return {
                            ...prevState,
                            listTotal: payload.count,
                            userList: payload.list,
                        }
                    })
                } else {
                    return errorMessage(message)
                }
            })
        }

        return () => {
        }
    }, [visible, messageId])

    const onSearch = value => {
        let {pp} = state

        setState(prevState => {
            return {
                ...prevState,
                searchValue: value
            }
        })
        getUserList(messageId, 0, pp, value).then(res => {
            const {message, code, payload} = res
            if (code === 200) {
                setState(prevState => {
                    return {
                        ...prevState,
                        listTotal: payload.count,
                        userList: payload.list
                    }
                })
            } else {
                return errorMessage(message)
            }
        })
    }

    const getUserList = async (messageId = null, start = 0, end = 30, search = "") => {
        setState(prevState => {
            return {
                ...prevState,
                loader: true
            }
        })
        const queryParams = {
            _start: start,
            _end: end,
            search,
            messageId
        }
        let res = await axios.get(`${apiUrl}/chat/see-recipients`, {
            params: queryParams,
            headers: authHeader()
        });

        const {data} = res;

        if (data.error === null) {
            const {count, phoneNumbers} = data.payload
            setState(prevState => {
                return {
                    ...prevState,
                    loader: false
                }
            })

            return {
                code: 200,
                payload: {
                    count,
                    list: phoneNumbers,
                }
            }
        } else {
            setState(prevState => {
                return {
                    ...prevState,
                    loader: false
                }
            })

            return {
                code: 400,
                message: data.error.message ? data.error.message : 'Something went wrong!'
            }
        }
    }

    const getMoreUsers = async () => {
        let {userHasMore, userList, pp, listTotal, searchValue} = state

        if (!userHasMore) {
            return true
        }

        if (userList.length === listTotal) {
            setState(prevState => {
                return {
                    ...prevState,
                    userHasMore: false,
                }
            });
            return true
        }

        setState(prevState => {
            return {
                ...prevState,
                loader: true
            }
        });

        const start = userList.length,
            end = parseInt(userList.length) + pp;

        getUserList(messageId, start, end, searchValue).then(res => {
            const {message, code, payload} = res
            if (code === 200) {
                userList = userList.concat(payload.list)
                setState(prevState => {
                    return {
                        ...prevState,
                        loader: false,
                        userList,
                    }
                })
            } else {
                setState(prevState => {
                    return {
                        ...prevState,
                        loader: false,
                    }
                })
                return errorMessage(message)
            }
        })
    }

    const {
        loader,
        showModal,
        userList,
        userHasMore,
    } = state;

    return (
        <Modal
            centered
            closable={true}
            closeIcon={(
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                    <path id="md-close"
                          d="M23.523,9.123l-1.6-1.6-6.4,6.4-6.4-6.4-1.6,1.6,6.4,6.4-6.4,6.4,1.6,1.6,6.4-6.4,6.4,6.4,1.6-1.6-6.4-6.4Z"
                          transform="translate(-7.523 -7.523)" fill="#696969"/>
                </svg>
            )}
            maskClosable={false}
            visible={showModal}
            className="ant-modal-create-thread"
            onCancel={() => {
                setState(prevState => {
                    return {
                        ...prevState,
                        showModal: false,
                        showUsersList: false,
                        listTotal: 0,
                        userList: {}
                    }
                });
            }}
            afterClose={() => {
                props.handleClose(true);
            }}
            footer={null}
        >
            {
                loader ? (
                    <div id="Loader" className="loading-box">
                        <Spin size="large"/>
                    </div>
                ) : null
            }
            <div className="users-box">
                <div className="field-box">
                    <Search
                        autoComplete={new Date().valueOf()}
                        placeholder="Enter country code"
                        onSearch={onSearch}
                    />
                </div>

                <div className="users-list">
                    {userList.length ? (
                        <InfiniteScroll
                            dataLength={userList.length}
                            next={getMoreUsers}
                            hasMore={userHasMore}
                            loader={<div className="users-list-loader">Loading...</div>}
                            height={300}
                            endMessage={
                                <p style={{textAlign: "center"}}>
                                    <b>Yay! You have seen it all</b>
                                </p>
                            }
                        >
                            {Object.keys(userList).map(key => {
                                let item = userList[key]
                                return (<div key={key} className="users-list-item">{item}</div>)
                            })}
                        </InfiniteScroll>
                    ) : (
                        "No Data"
                    )}
                </div>
            </div>
        </Modal>
    )
}

export default RecipientsList;
