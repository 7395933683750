const threadReducer = (state = [], action) => {

    switch (action.type) {
        case 'NEW_THREAD':
            return action.data
        default:
            return state
    }
}

export default threadReducer;
