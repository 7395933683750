/* eslint-disable no-template-curly-in-string */
import React, {useEffect, useState} from "react";
import Modal from "antd/lib/modal";
import Spin from "antd/lib/spin";
import Button from "antd/lib/button";
import Form from 'antd/lib/form';
import Input from 'antd/lib/input';
import Checkbox from "antd/lib/checkbox";
import {authHeader, errorMessage} from "../../../_helpers";
import axios from "axios";
import InfiniteScroll from "react-infinite-scroll-component";
import {ReactComponent as RemoveIcon} from "../../../Assets/svg/icon-remove.svg";

const {Search} = Input;
const apiUrl = process.env.REACT_APP_API;

const ThreadCreate = (props) => {
    const {visible} = props
    const [createThreadForm] = Form.useForm();
    const [state, setState] = useState({
        userHasMore: true,
        loader: false,
        showModal: false,
        showUsersList: false,
        usersType: '',
        recipientsList: [],
        userList: {},
        pp: 30,
        searchValue: '',
        listTotal: 0,
        selectedUsersList: {
            destination: [],
            source: []
        },
        source: [],
        destination: []
    })
    const [validationMessage, setValidationMessage] = useState('')
    const [autoReplyEmpty, autoReplyEmptyState] = useState(true);
    const validateMessages = {
        required: '${label} is required!',
    };

    const onFinishFailed = errorInfo => {
        console.error('Failed:', errorInfo);
    };

    useEffect(() => {
        if (visible) {
            setState(prevState => {
                return {
                    ...prevState,
                    showModal: true
                }
            })

            createThreadForm.setFieldsValue({
                oneDirectionalChat: false,
                adminOffline: false
            })
        }
        return () => {
        }
    }, [visible, createThreadForm])

    const CreateThread = async () => {
        let {source, destination} = state
        let values = createThreadForm.getFieldsValue()

        let {name, oneDirectionalChat, threadsAutoReplayText, adminOffline} = values
        const postData = {
            name,
            oneDirectionalChat: oneDirectionalChat,
            patterns: [],
            patternOfNumberToCall: []
        }

        postData['threadsAutoReplies'] = [{
            text: threadsAutoReplayText ? threadsAutoReplayText : '',
            type: "welcome",
            adminOffline
        }]

        if (source.length) {
            Object.keys(source).map((key) => {
                let sourceItem = source[key]

                return postData.patterns.push(sourceItem.pattern)
            })
        }

        if (destination.length) {
            Object.keys(destination).map((key) => {
                let sourceItem = destination[key]

                return postData.patternOfNumberToCall.push(sourceItem.pattern)
            })
        }

        let res = await axios.post(`${apiUrl}/threads/create`, postData, {
            headers: authHeader()
        });

        const {data} = res;

        if (data.error === null) {
            setState(prevState => {
                return {
                    ...prevState,
                    loader: false,
                    showModal: false,
                    showUsersList: false
                }
            })

            props.handleSuccess({flag: true, message: "Thread successfully created"})
        } else {
            setState(prevState => {
                return {
                    ...prevState,
                    loader: false
                }
            })

            return errorMessage(data.error.message)
        }
    }

    const onSearch = value => {
        let {usersType, pp} = state
        value = value.replace(/\s/g, '');
        setState(prevState => {
            return {
                ...prevState,
                searchValue: value
            }
        })
        getUserList(usersType, 0, pp, value).then(res => {
            const {message, code, payload} = res
            if (code === 200) {
                setState(prevState => {
                    return {
                        ...prevState,
                        listTotal: payload.count,
                        userList: payload.list
                    }
                })
            } else {
                return errorMessage(message)
            }
        })
    }

    const getUserList = async (type = null, start = 0, end = 30, search = "") => {
        setState(prevState => {
            return {
                ...prevState,
                loader: true
            }
        })
        const queryParams = {
            _start: start,
            _end: end,
            search,
            type
        }
        let res = await axios.get(`${apiUrl}/users/list`, {
            params: queryParams,
            headers: authHeader()
        });

        const {data} = res;

        if (data.error === null) {
            const {count, rows} = data.payload
            setState(prevState => {
                return {
                    ...prevState,
                    loader: false
                }
            })

            return {
                code: 200,
                payload: {
                    count,
                    list: rows,
                }
            }
        } else {
            setState(prevState => {
                return {
                    ...prevState,
                    loader: false
                }
            })

            return {
                code: 400,
                message: data.error.message ? data.error.message : 'Something went wrong!'
            }
        }
    }

    const getMoreUsers = async () => {
        let {userHasMore, usersType, userList, pp, listTotal, searchValue} = state

        if (!userHasMore) {
            return true
        }

        if (userList.length === listTotal) {
            setState(prevState => {
                return {
                    ...prevState,
                    userHasMore: false,
                }
            });
            return true
        }

        setState(prevState => {
            return {
                ...prevState,
                loader: true
            }
        });

        const start = userList.length,
            end = parseInt(userList.length) + pp;

        getUserList(usersType, start, end, searchValue).then(res => {
            const {message, code, payload} = res
            if (code === 200) {
                userList = userList.concat(payload.list)
                setState(prevState => {
                    return {
                        ...prevState,
                        loader: false,
                        userList,
                    }
                })
            } else {
                setState(prevState => {
                    return {
                        ...prevState,
                        loader: false,
                    }
                })
                return errorMessage(message)
            }
        })
    }

    const addPattern = async () => {
        const {searchValue, selectedUsersList, usersType, listTotal} = state
        setValidationMessage('')
        if (!selectedUsersList[usersType].length && !searchValue) {
            setState(prevState => {
                return {
                    ...prevState,
                    [usersType]: state[usersType].concat({
                        type: 'all',
                        pattern: '*',
                        count: listTotal
                    }),
                    showUsersList: false,
                    usersType: '',
                    listTotal: 0,
                    userList: {},
                    userHasMore: true,
                    selectedUsersList: {
                        destination: [],
                        source: []
                    },
                }
            });
            return true
        }
        if (selectedUsersList[usersType].length) {
            setState(prevState => {
                return {
                    ...prevState,
                    [usersType]: state[usersType].concat(selectedUsersList[usersType]),
                    showUsersList: false,
                    usersType: '',
                    listTotal: 0,
                    userList: {},
                    userHasMore: true,
                    searchValue: '',
                    selectedUsersList: {
                        destination: [],
                        source: []
                    },
                }
            });
            return true

        }
        if (searchValue && selectedUsersList[usersType].length) {
            setState(prevState => {
                return {
                    ...prevState,
                    [usersType]: state[usersType].concat({
                        type: 'search',
                        pattern: searchValue,
                        count: listTotal
                    }),
                    searchValue: '',
                    showUsersList: false,
                    usersType: '',
                    listTotal: 0,
                    userList: {},
                    userHasMore: true,
                    selectedUsersList: {
                        destination: [],
                        source: []
                    },
                }
            });
            return true
        }
    }

    const {
        loader,
        showModal,
        showUsersList,
        usersType,
        listTotal,
        userList,
        userHasMore,
        selectedUsersList,
        destination,
        source
    } = state;

    return (
        <Modal
            centered
            closable={true}
            closeIcon={(
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                    <path id="md-close"
                          d="M23.523,9.123l-1.6-1.6-6.4,6.4-6.4-6.4-1.6,1.6,6.4,6.4-6.4,6.4,1.6,1.6,6.4-6.4,6.4,6.4,1.6-1.6-6.4-6.4Z"
                          transform="translate(-7.523 -7.523)" fill="#696969"/>
                </svg>
            )}
            maskClosable={false}
            visible={showModal}
            className="ant-modal-create-thread"
            onCancel={() => {
                setState(prevState => {
                    return {
                        ...prevState,
                        showModal: false,
                        showUsersList: false,
                        usersType: '',
                        listTotal: 0,
                        userList: {}
                    }
                });
            }}
            afterClose={() => {
                createThreadForm.resetFields()
                props.handleClose(true);
            }}
            footer={
                showUsersList ?
                    (
                        <Button type="primary" onClick={() => {
                            return addPattern()
                        }}>
                            Add
                            ({selectedUsersList[usersType].length ? selectedUsersList[usersType].length : listTotal} users)
                        </Button>
                    ) : (
                        <Button type="primary" onClick={() => {
                            createThreadForm
                                .validateFields()
                                .then(() => {
                                    if (!source.length && !destination.length) {
                                        setValidationMessage('Add Recipients by Source Pattern or Recipients by Destination Pattern')
                                        return true
                                    }
                                    setState(prevState => {
                                        return {
                                            ...prevState,
                                            loader: true
                                        }
                                    });
                                    return CreateThread();
                                })
                                .catch(info => {
                                    console.log('Validate Failed:', info);
                                });
                        }}>
                            Create Thread
                        </Button>
                    )
            }
        >
            {
                loader ? (
                    <div id="Loader" className="loading-box">
                        <Spin size="large"/>
                    </div>
                ) : null
            }

            <div className="ant-modal-title">
                {showUsersList ? usersType === 'source' ? 'Recipients by Source Pattern' : 'Recipients by Destination Pattern' : 'New Thread'}
            </div>
            {showUsersList && (
                <div className="back-btn hide-user-list" onClick={() => {
                    setState(prevState => {
                        return {
                            ...prevState,
                            showUsersList: false,
                            usersType: '',
                            listTotal: 0,
                            userList: {},
                            userHasMore: true
                        }
                    })
                }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="18" viewBox="0 0 10 18">
                        <path
                            d="M14.265,15.191l6.618-6.806a1.307,1.307,0,0,0,0-1.817,1.231,1.231,0,0,0-1.771,0l-7.5,7.712a1.31,1.31,0,0,0-.036,1.773l7.529,7.764a1.232,1.232,0,0,0,1.77,0,1.307,1.307,0,0,0,0-1.817Z"
                            transform="translate(-11.25 -6.193)" fill="#001d40"/>
                    </svg>
                </div>
            )}
            <Form
                form={createThreadForm}
                name="thread_create_form"
                onFinishFailed={onFinishFailed}
                validateMessages={validateMessages}
                initialValues={{adminOffline: false, oneDirectionalChat: false}}

            >
                {showUsersList ? (
                    <div className="users-box">
                        <div className="field-box">
                            <Search
                                autoComplete={new Date().valueOf()}
                                placeholder="Enter country code"
                                onSearch={onSearch}
                            />
                        </div>

                        <div className="users-list">
                            {userList.length ? (
                                <InfiniteScroll
                                    dataLength={userList.length}
                                    next={getMoreUsers}
                                    hasMore={userHasMore}
                                    loader={<div className="users-list-loader">Loading...</div>}
                                    height={300}
                                    endMessage={
                                        <p style={{textAlign: "center"}}>
                                            <b>Yay! You have seen it all</b>
                                        </p>
                                    }
                                >
                                    {Object.keys(userList).map(key => {
                                        let item = userList[key]
                                        let {usr_id} = item
                                        return (<div key={key} className="users-list-item" onClick={e => {
                                            let {selectedUsersList} = state
                                            let selectedUsersArr = selectedUsersList[usersType]
                                            if (selectedUsersArr.find(item => item.id === usr_id)) {
                                                let itemIndex = selectedUsersArr.indexOf(selectedUsersArr.find(item => item.id === usr_id))
                                                selectedUsersArr.splice(itemIndex, 1)

                                                setState(prevState => {
                                                    return {
                                                        ...prevState,
                                                        selectedUsersList: {
                                                            ...prevState.selectedUsersList,
                                                            [usersType]: selectedUsersArr
                                                        }
                                                    }
                                                })
                                                e.target.classList.remove('selected')
                                                return true
                                            }
                                            e.target.classList.add('selected')
                                            selectedUsersArr.push({
                                                type: 'single',
                                                pattern: usr_id.toString(),
                                                id: usr_id
                                            })

                                            setState(prevState => {
                                                return {
                                                    ...prevState,
                                                    selectedUsersList: {
                                                        ...prevState.selectedUsersList,
                                                        [usersType]: selectedUsersArr
                                                    }
                                                }
                                            })
                                        }}>{usr_id}</div>)
                                    })}
                                </InfiniteScroll>
                            ) : (
                                usersType === 'destination' ? ("search pattern for get list") : ("No Data")
                            )}
                        </div>
                    </div>
                ) : (
                    <div className="form-box">

                        <div className="field-row">
                            <Form.Item
                                name="name"
                                label="Thread Title"
                                rules={[{required: true}]}>
                                <Input placeholder="Enter Thread Title"/>
                            </Form.Item>
                        </div>

                        <div className="field-row">
                            <Form.Item
                                name="threadsAutoReplayText"
                                label="Autoreply">
                                <Input placeholder="Enter Text for Autoreply" onChange={e => {
                                    let value = e.target.value

                                    if (value !== '') {
                                        autoReplyEmptyState(false)
                                    } else {
                                        autoReplyEmptyState(true)
                                        createThreadForm.setFieldsValue({
                                            adminOffline: false
                                        })
                                    }
                                }}/>
                            </Form.Item>
                        </div>

                        <div className="field-row blank-row">
                            <div className="ant-row ant-form-item" style={{rowGap: "0px"}}>
                                <div className="ant-col ant-form-item-label"><label
                                    title="Recipients">Recipients by Source Pattern</label></div>
                                <div className="ant-col ant-form-item-control">
                                    <div className="ant-form-item-control-input">
                                        <div className="ant-form-item-control-input-content">
                                            <div className="pattern-box">
                                                {Object.keys(source).map((key) => {
                                                    let sourceItem = source[key]
                                                    let {type, pattern, count} = sourceItem
                                                    let labelText = ''

                                                    if (type === "all") {
                                                        labelText = `<span>${pattern}</span> / ${count}users`
                                                    }

                                                    if (type === "search") {
                                                        labelText = `<span>+${pattern}</span> / ${count}users`
                                                    }

                                                    if (type === "single") {
                                                        labelText = `+${pattern}`
                                                    }
                                                    return (
                                                        <div key={key} className="label" datatype={type}>
                                                            <span dangerouslySetInnerHTML={{__html: labelText}}/>
                                                            <RemoveIcon onClick={() => {
                                                                source.splice(parseInt(key), 1)

                                                                setState(prevState => {
                                                                    return {
                                                                        ...prevState,
                                                                        source
                                                                    }
                                                                })
                                                            }
                                                            }/>
                                                        </div>
                                                    )
                                                })}
                                            </div>

                                            <div className="add-remove-icon" onClick={() => {
                                                setState(prevState => {
                                                    return {
                                                        ...prevState,
                                                        showUsersList: true,
                                                        usersType: 'source'
                                                    }
                                                })
                                                getUserList('source').then(res => {
                                                    const {message, code, payload} = res
                                                    if (code === 200) {
                                                        setState(prevState => {
                                                            return {
                                                                ...prevState,
                                                                listTotal: payload.count,
                                                                userList: payload.list
                                                            }
                                                        })
                                                    } else {
                                                        return errorMessage(message)
                                                    }
                                                })
                                            }}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20"
                                                     viewBox="0 0 20 20">
                                                    <g transform="translate(-3 -3)">
                                                        <path
                                                            d="M13,21a8,8,0,1,1,8-8A8,8,0,0,1,13,21ZM3,13A10,10,0,1,1,13,23,10,10,0,0,1,3,13Zm9-5v2H10v2h2v2h2V12h2V10H14V8Zm-2,8v2h6V16Z"
                                                            transform="translate(0 0)" fill="#00c0e7"
                                                            fillRule="evenodd"/>
                                                    </g>
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="field-row blank-row">
                            <div className="ant-row ant-form-item" style={{rowGap: "0px"}}>
                                <div className="ant-col ant-form-item-label"><label
                                    title="Recipients">Recipients by Destination Pattern</label></div>
                                <div className="ant-col ant-form-item-control">
                                    <div className="ant-form-item-control-input">
                                        <div className="ant-form-item-control-input-content">
                                            <div className="pattern-box">
                                                {Object.keys(destination).map((key) => {
                                                    let sourceItem = destination[key]
                                                    let {type, pattern, count} = sourceItem
                                                    let labelText = ''

                                                    if (type === "all") {
                                                        labelText = `<span>${pattern}</span> / ${count}users`
                                                    }

                                                    if (type === "search") {
                                                        labelText = `<span>+${pattern}</span> / ${count}users`
                                                    }

                                                    if (type === "single") {
                                                        labelText = `+${pattern}`
                                                    }
                                                    return (
                                                        <div key={key} className="label" datatype={type}>
                                                            <span dangerouslySetInnerHTML={{__html: labelText}}/>
                                                            <RemoveIcon onClick={() => {
                                                                destination.splice(parseInt(key), 1)

                                                                setState(prevState => {
                                                                    return {
                                                                        ...prevState,
                                                                        destination
                                                                    }
                                                                })
                                                            }
                                                            }/>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                            <div className="add-remove-icon" onClick={() => {
                                                setState(prevState => {
                                                    return {
                                                        ...prevState,
                                                        showUsersList: true,
                                                        usersType: 'destination'
                                                    }
                                                })
                                            }}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20"
                                                     viewBox="0 0 20 20">
                                                    <g transform="translate(-3 -3)">
                                                        <path
                                                            d="M13,21a8,8,0,1,1,8-8A8,8,0,0,1,13,21ZM3,13A10,10,0,1,1,13,23,10,10,0,0,1,3,13Zm9-5v2H10v2h2v2h2V12h2V10H14V8Zm-2,8v2h6V16Z"
                                                            transform="translate(0 0)" fill="#00c0e7"
                                                            fillRule="evenodd"/>
                                                    </g>
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="field-row checkbox-row">
                            <Form.Item name="oneDirectionalChat" valuePropName="checked">
                                <Checkbox>One-direction chat</Checkbox>
                            </Form.Item>
                        </div>

                        <div className="field-row checkbox-row">
                            <Form.Item name="adminOffline" valuePropName="checked">
                                <Checkbox disabled={autoReplyEmpty}>Autoreply only when admin is offline</Checkbox>
                            </Form.Item>
                        </div>

                        {validationMessage && (<div className="error-message">{validationMessage}</div>)}
                    </div>
                )}
            </Form>
        </Modal>
    )
}

export default ThreadCreate;
