/* eslint-disable no-template-curly-in-string */
import React, {useState} from 'react';
import {ReactComponent as AttachIcon} from "../../Assets/svg/attach-icon.svg";
import {ReactComponent as SendIcon} from "../../Assets/svg/icon-send.svg";
import socket from "../../socketConfig";
import {errorMessage, getLocalStorage} from "../../_helpers";
import Image from 'antd/lib/image'
import axios from "axios";
import {useSelector} from "react-redux";

const apiUrl = process.env.REACT_APP_API;
const user = getLocalStorage("user", true)

const ChatFooter = ({conversationId}) => {
    const threadData = useSelector(globalState => globalState.threadData)

    const [state, setState] = useState({
        loader: false,
        uploaderLoader: false,
        image: '',
        file: ''
    })

    const sendMsg = async (ele = null, val, type) => {
        if (threadData.deleted) {
            return errorMessage('This Thread is deleted!')
        }
        if (!val) {
            return true
        }

        socket.emit('message', {
            threadId: conversationId,
            messageType: type,
            content: val,
            pattern: threadData.parentId ? threadData.userPhoneNumber : ''
        }, (confirmation) => {
            if (confirmation) {
                if (type === 'plain') {
                    ele.value = ''
                    ele.style.cssText = 'height:36px';
                }
                if (type === 'image') {
                    setState(prevState => {
                        return {
                            ...prevState,
                            file: '',
                            image: ''
                        }
                    })
                }
            } else {
                return errorMessage('Something went wrong!')
            }
        })
    }

    const onEnterPress = (e) => {
        let element = e.target
        if (e.keyCode === 13 && e.shiftKey === false) {
            e.preventDefault();
            if (element.value !== '' && element.value.replace(/\s/g, '').length) {
                return sendMsg(element, element.value, 'plain')
            }
        } else if (e.keyCode === 13 && e.shiftKey === true) {
            setTimeout(function () {
                element.style.cssText = 'height:' + element.scrollHeight + 'px';
            }, 1);
        }
    }

    const getBase64 = (img, callback) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    }

    const handleFileChange = async e => {
        let filesArr = e.target.files
        let file = filesArr[0]

        setState(prevState => {
            return {
                ...prevState,
                uploaderLoader: true
            }
        })

        // const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';

        const isJpgOrPng = (/(gif|jpe?g|tiff?|png|webp|bmp)$/i).test(file.type)

        if (!isJpgOrPng) {
            setState(prevState => {
                return {
                    ...prevState,
                    uploaderLoader: false
                }
            })
            await errorMessage('You can only upload JPG/PNG file!')
            return false
        }

        getBase64(file, imageUrl => {
                setState(prevState => {
                    return {
                        ...prevState,
                        uploaderLoader: false,
                        image: imageUrl,
                        file
                    }
                })
            }
        );
    };

    const uploadFile = async e => {
        const {file} = state
        const postData = new FormData();

        postData.append('imageMessage', file);
        postData.append('threadId', conversationId);

        let res = await axios.post(`${apiUrl}/chat/upload-image`, postData, {
            headers: {
                'content-type': 'multipart/form-data',
                'authorization': user.token
            }
        });
        let {data} = res;

        if (data.error === null) {
            return {
                success: true,
                fileUrl: data.payload.url
            }
        } else {
            return errorMessage(data.error.message)
        }
    }

    const {image, file} = state
    let styles = {}

    if (image) {
        styles['padding'] = '9px 15px'
    }

    return (
        <div className="chat-box-footer" style={styles}>
            <div className="attach-box">
                <input className="hidden" type="file" id="file"
                       value={''} onChange={handleFileChange}/>
                {image ? (
                    <div className="attached-file" onClick={() => {
                        setState(prevState => {
                            return {
                                ...prevState,
                                image: '',
                                file: ''
                            }
                        })
                    }}>
                        <Image preview={false} src={image} alt="avatar" style={{width: '100%'}}/>
                    </div>
                ) : (
                    <label htmlFor="file">
                        <div className="attach-icon">
                            <AttachIcon/>
                        </div>
                    </label>
                )}
            </div>

            <div className="field-box">
                <textarea onChange={e => {
                    if (e.target.value === '') {
                        e.target.style.cssText = 'height:36px';
                    } else {
                        e.target.style.cssText = `height:${e.target.scrollHeight}px`;
                    }
                }} className="field-item" id="chat-input" rows="5" placeholder="Type a message…"
                          onKeyDown={onEnterPress}/>
            </div>

            <div className="send-icon">
                <SendIcon onClick={() => {
                    if (file) {
                        uploadFile().then(res => {
                            let {success, fileUrl} = res
                            if (success) {
                                return sendMsg(null, fileUrl, 'image')
                            }
                        })
                    } else {
                        let element = document.getElementById('chat-input')
                        return sendMsg(element, element.value, 'plain')
                    }
                }}/>
            </div>
        </div>
    )
}
export default ChatFooter;
