/* eslint-disable no-template-curly-in-string */
import React, {useState, useEffect} from 'react';
import Input from 'antd/lib/input';
import {authHeader, errorMessage} from "../../_helpers";
import axios from "axios";
import Spin from "antd/lib/spin";
import InfiniteScroll from "react-infinite-scroll-component";
import {useDispatch, useSelector} from "react-redux";
import {thread, threadRemovedAction, newThreadAction, updateRemovedAction} from "../../actions";

const {Search} = Input;
const apiUrl = process.env.REACT_APP_API;

const ChatParentListComponent = ({conversationId}) => {
    const dispatch = useDispatch()
    const threadData = useSelector(globalState => globalState.threadData)
    const threadRemoved = useSelector(globalState => globalState.threadRemoved)
    const newThread = useSelector(globalState => globalState.newThread)
    const updateThread = useSelector(globalState => globalState.updateThread)


    const [state, setState] = useState({
        hasMore: true,
        listReady: false,
        loader: true,
        searchValue: '',
        pp: 50,
        list: [],
        listTotal: 0,
        activeChatIndex: threadData.id
    })

    useEffect(() => {
        if (threadData.id !== state.activeChatIndex) {
            setState(prevState => {
                return {
                    ...prevState,
                    activeChatIndex: null
                }
            })
        }

        return () => {
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [threadData])

    useEffect(() => {
        if (threadRemoved && threadRemoved.threadId) {
            removeFromList()
        }
        return () => {

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [threadRemoved])

    useEffect(() => {
        let {list} = state
        if (newThread && newThread.id && !newThread.oldThreadId) {
            list.unshift(newThread)
            setState(prevState => {
                return {
                    ...prevState,
                    list,
                }
            });
            dispatch(newThreadAction({}))
            return true
        }
        if(newThread && newThread.oldThreadId) {
            let itemIndex = list.indexOf(list.find(item => item.id === newThread.oldThreadId))
            if (itemIndex >= 0) {
                list[itemIndex].name = newThread.name

                setState(prevState => {
                    return {
                        ...prevState,
                        list
                    }
                })
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newThread])

    useEffect(() => {
        if (updateThread && updateThread.id) {
            let {list} = state
            let itemIndex = list.indexOf(list.find(item => item.id === updateThread.id))
            if (itemIndex >= 0) {
                list[itemIndex].name = updateThread.name

                setState(prevState => {
                    return {
                        ...prevState,
                        list
                    }
                })
            }
            dispatch(updateRemovedAction({}))
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateThread])

    const removeFromList = () => {
        let {list} = state
        let itemIndex = list.indexOf(list.find(item => item.id === threadRemoved.threadId))
        if(itemIndex >= 0) {
            list[itemIndex]['deleted'] = true
        }
        setState(prevState => {
            return {
                ...prevState,
                list: list
            }
        })
        dispatch(threadRemovedAction({}))

        if (threadRemoved.threadId === threadData.id) {
            dispatch(thread({}))
        }
    }

    useEffect(() => {
        getList().then(res => {
            let {code, payload, message} = res

            if (code === 200) {
                setState(prevState => {
                    return {
                        ...prevState,
                        listReady: true,
                        listTotal: payload.count,
                        list: payload.list
                    }
                })
            } else {
                return errorMessage(message)
            }
        })
        return () => {
        }
    }, [])

    const getList = async (start = 0, end = 50, search = "") => {
        setState(prevState => {
            return {
                ...prevState,
                loader: true
            }
        })
        const queryParams = {
            _start: start,
            _end: end,
            search,
            type: 'parent'
        }
        let res = await axios.get(`${apiUrl}/threads/list`, {
            params: queryParams,
            headers: authHeader()
        });

        const {data} = res;

        if (data.error === null) {
            const {count, rows} = data.payload
            setState(prevState => {
                return {
                    ...prevState,
                    loader: false
                }
            })

            return {
                code: 200,
                payload: {
                    count,
                    list: rows,
                }
            }
        } else {
            setState(prevState => {
                return {
                    ...prevState,
                    loader: false
                }
            })

            return {
                code: 400,
                message: data.error.message ? data.error.message : 'Something went wrong!'
            }
        }
    }

    const onSearch = value => {
        const {pp} = state
        setState(prevState => {
            return {
                ...prevState,
                searchValue: value
            }
        })
        getList(0, pp, value).then(res => {
            const {message, code, payload} = res
            if (code === 200) {
                setState(prevState => {
                    return {
                        ...prevState,
                        listTotal: payload.count,
                        list: payload.list
                    }
                })
            } else {
                return errorMessage(message)
            }
        })
    }

    const getMore = async () => {
        let {hasMore, list, pp, listTotal, searchValue} = state

        if (!hasMore) {
            return true
        }

        if (list.length === listTotal) {
            setState(prevState => {
                return {
                    ...prevState,
                    hasMore: false,
                }
            });
            return true
        }

        setState(prevState => {
            return {
                ...prevState,
                loader: true
            }
        });

        const start = list.length,
            end = parseInt(list.length) + pp;

        getList(start, end, searchValue).then(res => {
            const {message, code, payload} = res
            if (code === 200) {
                list = list.concat(payload.list)
                setState(prevState => {
                    return {
                        ...prevState,
                        loader: false,
                        list,
                    }
                })
            } else {
                return errorMessage(message)
            }
        })
    }

    const handleThreadData = data => {
        dispatch(thread(data))
        conversationId(data.id)
    }

    const {list, listReady, loader, activeChatIndex, hasMore} = state
    return (
        <div className="parent-box">
            <div className="search-box">
                <div className="field-box">
                    <Search
                        autoComplete={new Date().valueOf()}
                        placeholder="Search"
                        onSearch={onSearch}
                    />
                </div>
            </div>
            <div id="parentScrollableDiv" className="parent-list-box messages-list-box"
                 style={{height: "100%", overflowY: "scroll"}}>
                {loader && (
                    <div id="Loader" className="loading-box">
                        <Spin size="large"/>
                    </div>
                )}
                {listReady && (
                    <>
                        <InfiniteScroll
                            dataLength={Object.keys(list).length}
                            next={getMore}
                            hasMore={hasMore}
                            scrollableTarget="parentScrollableDiv"
                            loader={Object.keys(list).length ? (<Spin size="large"/>) : ''}
                        >
                            {list.map((item, key) => {
                                const {id, name,deleted} = item
                                return (
                                    <div
                                        className={`parent-list-item messages-list-item ${deleted ? 'deleted' : ''} ${activeChatIndex === id ? "active" : ""}`}
                                        key={key}
                                        onClick={e => {
                                            if (e.currentTarget.classList.contains('unread')) {
                                                e.currentTarget.classList.remove('unread')
                                            }
                                            setState(prevState => {
                                                return {
                                                    ...prevState,
                                                    activeChatIndex: id
                                                }
                                            })
                                            item['type'] = "parent"
                                            handleThreadData(item)
                                        }}>
                                        <div className="thread-name">{name}</div>
                                        <div className="users-count"/>
                                    </div>
                                )
                            })}
                        </InfiniteScroll>
                        {!Object.keys(list).length && (
                            <div className="no-data">No Threads</div>
                        )}
                    </>

                )}
            </div>
        </div>
    )
}

export default ChatParentListComponent;
