/* eslint-disable no-template-curly-in-string */
import React, {useEffect, useState} from 'react';
import Form from "antd/lib/form";
import Input from "antd/lib/input";
import Radio from "antd/lib/radio";
import axios from "axios";
import {authHeader, errorMessage} from "../../../_helpers";

const apiUrl = process.env.REACT_APP_API;

const TemplateTypeButton = (props) => {
    let {error, data} = props
    const [textForm] = Form.useForm();
    const [state, setState] = useState({
        appScreenList: {}
    })
    const validateMessages = {
        required: '${label} is required!',
    };

    useEffect(() => {
        if (error) {
            if (!textForm.getFieldValue('screenId')) {
                textForm.setFields([
                    {
                        name: 'screenId',
                        errors: ['App Screen is required!'],
                    },
                ]);
            }

            if (!textForm.getFieldValue('text')) {
                textForm.setFields([
                    {
                        name: 'text',
                        errors: ['Text is required!'],
                    },
                ]);
            }

        }
        return () => {
        }
    }, [error, textForm])

    useEffect(() => {
        if (data) {
            textForm.setFieldsValue({
                text: data.data,
                screenId: data.screenId,
                additionalData: data.additionalData
            });
        }
        return () => {
        }
    }, [data, textForm])

    useEffect(() => {
        getAppScreenData().then(res => {
            let {payload, code, message} = res

            if (code === 200) {
                setState(prevState => {
                    return {
                        appScreenList: payload
                    }
                })
            } else {
                return errorMessage(message)
            }
        })
    }, [])

    const getAppScreenData = async () => {

        let res = await axios.get(`${apiUrl}/screens/list`, {
            headers: authHeader()
        });

        let {data} = res

        if (data.error === null) {
            return {
                code: 200,
                payload: data.payload
            }
        } else {
            return {
                code: 400,
                message: data.error.message ? data.error.message : 'Something went wrong!'
            }
        }
    }

    let {appScreenList} = state

    return (
        <Form
            form={textForm}
            name="create-template-button-form"
            validateMessages={validateMessages}
            className="create-template-button-form"
            initialValues={{
                'text': '',
                'additionalData': ''
            }}
            onChange={() => {
                props.handleData({
                    data: textForm.getFieldValue('text'),
                    additionalData: textForm.getFieldValue('additionalData'),
                    screenId: textForm.getFieldValue('screenId')
                })
            }}
        >
            <div className="field-row">
                <Form.Item
                    name="text"
                    label="Text"
                    rules={[{required: true}]}
                >
                    <Input placeholder="Enter Text for Template" onChange={(e) => {
                        if (textForm.getFieldValue('text')) {
                            textForm.setFields([
                                {
                                    name: 'text',
                                    validating: true,
                                    errors: null,
                                }
                            ]);
                        }
                    }}/>
                </Form.Item>

                <Form.Item
                    name="additionalData"
                    label="Additional Data"
                >
                    <Input placeholder="Enter Text for Template"/>
                </Form.Item>

                <Form.Item
                    name="screenId"
                    label="App Screen"
                    rules={[{required: true}]}
                >
                    <Radio.Group>
                        {Object.keys(appScreenList).map((key) => {
                            return (
                                <Radio key={key} value={appScreenList[key].id}>{appScreenList[key].description}</Radio>
                            )
                        })}
                    </Radio.Group>
                </Form.Item>
            </div>
        </Form>
    )
}

export default TemplateTypeButton;
