import loggedReducer from './isLogged';
import threadReducer from "./threadReducer";
import newMessageReducer from "./newMessage";
import threadRemovedReducer from "./threadRemoved";
import newThreadReducer from "./newThread";
import updateRemovedReducer from "./updateThread";
import {combineReducers} from 'redux';

const allReducers = combineReducers({
    isLogged: loggedReducer,
    threadData: threadReducer,
    newMessage: newMessageReducer,
    threadRemoved: threadRemovedReducer,
    newThread: newThreadReducer,
    updateThread: updateRemovedReducer
})

export default allReducers;
