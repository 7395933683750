/* eslint-disable no-template-curly-in-string */
import React, {useEffect, useState} from 'react';
import axios from "axios";
import {authHeader, errorMessage, successMessage} from "../../_helpers";
import ChatHeader from "./chatHeader.js";
import ChatFooter from "./chatFooter.js";
import InfiniteScroll from 'react-infinite-scroll-component';
import socket from "../../socketConfig";
import Image from "antd/lib/image";
import {ExclamationCircleOutlined} from "@ant-design/icons";
import Modal from "antd/lib/modal";
import {ReactComponent as ArrowRightIcon} from "../../Assets/svg/icon-arrow-right.svg";
import {useSelector} from "react-redux";
import RecipientsList from "./recipientsList";
import Popover from "antd/lib/popover";
import Spin from "antd/lib/spin";

const moment = require("moment");
const {confirm} = Modal;
const apiUrl = process.env.REACT_APP_API;
const _ = require('lodash');
const ChatBox = () => {
    const threadData = useSelector(globalState => globalState.threadData)
    const newMessage = useSelector(globalState => globalState.newMessage)
    const [state, setState] = useState({
        hasMore: true,
        pp: 30,
        messageItems: [],
        showMoreToolTip: null,
        recipientsModalProps: {
            visible: false,
            messageId: null
        },
        messageTotalItems: 0,
        sentIndex: null,
        seenIndex: null
    })
    const [loader, loaderState] = useState(true)
    const [messageItems, setMessageItems] = useState({})
    const [messageItemsLength, setMessageItemsLength] = useState(0)

    useEffect(() => {
        if (threadData && threadData.id) {
            socket.emit('inThread', {threadId: threadData.id})
            getAllMessages(threadData.id).then(res => {
                let {code, message, payload} = res

                if (code === 200) {
                    let list = payload.list
                    let sentIndex = null
                    if (list.length && list[0].sender !== "mobile") {
                        sentIndex = list[0].messageId
                    }
                    setState(prevState => {
                        return {
                            ...prevState,
                            messageTotalItems: payload.count,
                            sentIndex: sentIndex,
                        }
                    })
                    const groups = list.reduce((groups, message) => {
                        const date = message.createdAt.split('T')[0];
                        if (list[0]) {
                            let listItemDate = list[0].createdAt
                            if (!moment().isSame(listItemDate, 'days')) {
                                groups[moment().format('YYYY-MM-DD')] = [];
                            }
                        }
                        if (!groups[date]) {
                            groups[date] = [];
                        }
                        groups[date].push(message);
                        return groups;
                    }, {});

                    setMessageItemsLength(messageItemsLength + list.length)
                    setMessageItems(groups)
                    loaderState(false)
                } else {
                    return errorMessage(message ? message : 'Something went wrong please try again!')
                }
            })
        }
        return () => {
            loaderState(true)
            socket.emit('outOfThread', {threadId: threadData.id})
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [threadData])

    useEffect(() => {
        let msg = newMessage
        let newMessageItemIndex = null
        let id = msg.childThreadId ? msg.childThreadId : msg.threadId

        if (threadData.id === id) {
            let messageExist = _.find(messageItems, function (item) {
                return item.messageId === msg.messageId
            });

            if (!messageExist) {
                if (msg.sender === "mobile" && threadData.type === "parent") {
                    return true;
                }

                const date = msg.createdAt.split('T')[0];
                if (!messageItems[date]) {
                    messageItems[date] = []
                }
                messageItems[date].unshift(msg)

                if (msg.sender === "dashboard") {
                    newMessageItemIndex = messageItems[date].find(item => item.messageId === msg.messageId).messageId
                }
                setMessageItems(messageItems)
                setMessageItemsLength(messageItemsLength + 1);
                setState(prevState => {
                    return {
                        ...prevState,
                        sentIndex: newMessageItemIndex
                    }
                });
            }
        }

        return () => {
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newMessage])

    useEffect(() => {
        socket.on('seen', (msg) => {
            let {whoSawTheMessage, threadId, lastSeenMessageId} = msg
            let seenMessageIndex = null

            if (threadData.id === parseInt(threadId) && whoSawTheMessage === "mobile") {
                seenMessageIndex = lastSeenMessageId
                setState(prevState => {
                    return {
                        ...prevState,
                        sentIndex: null,
                        seenIndex: seenMessageIndex
                    }
                });
            }
        })
        return () => {
            socket.off('seen')
        }
    }, [state, threadData.id])

    useEffect(() => {
        socket.on('messageRemoved', (msg) => {
            let {createdAt: groupName, messageId} = msg
            groupName = groupName.split('T')[0]

            // eslint-disable-next-line array-callback-return
            let arr = messageItems[groupName].map((item, key) => {
                if (item.messageId === messageId) {
                    messageItems[groupName][key]['deleted'] = true
                    return messageItems[groupName][key]
                }
                return item
            })

            setMessageItems(prevState => {
                return {
                    ...prevState,
                    [groupName]: arr
                }
            })
        })
        return () => {
            socket.off('messageRemoved')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [messageItems, threadData.id])

    const getAllMessages = async (id, start = 0, end = 30) => {
        const queryParams = {
            threadId: id,
            _start: start,
            _end: end
        }
        let res = await axios.get(`${apiUrl}/chat/get-all-messages`, {
            params: queryParams,
            headers: authHeader()
        });

        const {data} = res;

        if (data.error === null) {
            let {count, rows} = data.payload
            return {
                code: 200,
                payload: {
                    count,
                    list: rows
                }
            }
        } else {
            return {
                code: 400,
                message: data.error.message
            }
        }
    }

    const fetchMoreData = () => {
        let {messageTotalItems, pp} = state
        if (messageItemsLength >= messageTotalItems) {
            setState(prevState => {
                return {
                    ...prevState,
                    hasMore: false
                }
            });
            return;
        }

        const start = parseInt(messageItemsLength),
            end = parseInt(messageItemsLength) + pp

        getAllMessages(threadData.id, start, end).then(res => {
            const {message, code, payload} = res
            if (code === 200) {
                let list = payload.list
                let arr = messageItems
                const groups = list.reduce((groups, message) => {
                    const date = message.createdAt.split('T')[0];
                    if (!groups[date]) {
                        groups[date] = [];
                    }
                    groups[date].push(message)
                    return groups;
                }, {});

                Object.keys(groups).map((date) => {
                    if (!arr[date]) {
                        arr[date] = [];
                    }
                    return groups[date].map((item) => {
                        return arr[date].push(item)
                    })
                });
                setMessageItemsLength(messageItemsLength + list.length)
                setMessageItems(arr)
            } else {
                return errorMessage(message)
            }
        })
    };

    const showDeleteConfirm = (messageId, groupName, messageIndex) => {
        return new Promise((resolve, reject) => {
            confirm({
                title: 'Are you sure you want to delete this message?',
                icon: <ExclamationCircleOutlined/>,
                okText: 'Yes',
                okType: 'danger',
                cancelText: 'No',
                className: "ant-modal-thread-delete-confirm",
                onOk: function () {
                    removeMessageByID(messageId, messageIndex).then(res => {
                        const {code, message} = res

                        if (code === 200) {
                            successMessage(message).then()
                            return resolve(true)
                        } else {
                            errorMessage(message).then()
                            return reject(message)
                        }
                    })
                }
            });
        })
    }

    const removeMessageByID = async (messageId) => {
        let queryParams = {
            messageId: messageId
        }
        let res = await axios.delete(`${apiUrl}/chat/remove-message`, {
            params: queryParams,
            headers: authHeader()
        });

        let {data} = res

        if (data.error === null) {
            return {
                code: 200,
                message: 'Message successfully deleted!'
            }
        } else {
            return {
                code: 400,
                message: data.error.message ? data.error.message : 'Something went wrong!'
            }
        }

    }

    const textToUrl = (text) => {
        let urlRegex = /(https?:\/\/[^\s]+)/g;
        return text.replace(urlRegex, function(url) {
            return '<a href="' + url + '" target="_blank">' + url + '</a>';
        })
    }

    const {recipientsModalProps, hasMore, showMoreToolTip, sentIndex, seenIndex} = state

    const {visible: showRecipientsModal} = recipientsModalProps

    function getMyText(date) {
        if (!moment.isMoment(date)) {
            date = moment(date); // ok for js date, milliseconds or string in ISO format
        }
        if (date.isSame(moment(), 'day')) {
            return "Today"
        } else if (date.isSame(moment().subtract(1, 'd'), 'day')) {
            return 'Yesterday';
        } else if (date.isSame(moment(), 'week')) {
            return date.format('dddd');
        } else {
            return date.format('DD.MM.YYYY');
        }
    }

    return (
        <div className="global-chat-box">
            <>
                <ChatHeader/>
                <div className="chat-content-box" id={`scrollableDiv-${threadData.id}`}
                     style={{
                         overflow: 'auto',
                         display: 'flex',
                         flexDirection: 'column-reverse',
                         padding: '20px 15px'
                     }}>
                    {loader ? (<Spin size="large" className="chat-content-box-loader"/>) : ('')}
                    <InfiniteScroll
                        key={threadData.id}
                        dataLength={messageItemsLength}
                        next={() => fetchMoreData()}
                        style={{
                            display: 'flex',
                            flexDirection: 'column-reverse'
                        }}
                        onScroll={() => {
                            if (showMoreToolTip) {
                                setState(prevState => {
                                    return {
                                        ...prevState,
                                        showMoreToolTip: null
                                    }
                                })
                            }
                        }}
                        inverse={true}
                        hasMore={hasMore}
                        scrollThreshold="700px"
                        loader={Object.keys(messageItems).length ? (<Spin size="large"/>) : ''}
                        scrollableTarget={`scrollableDiv-${threadData.id}`}
                    >
                        {Object.keys(messageItems).map((item, index) => {
                            let messageItemGroup = messageItems[item]
                            return (
                                <React.Fragment key={item}>
                                    {messageItemGroup.map((messageItem, key) => {
                                        let {content, messageId, messageType, sender, deleted} = messageItem
                                        let contentOBJ
                                        let textContent
                                        if (messageType === 'button') {
                                            contentOBJ = JSON.parse(content)
                                        }

                                        if(messageType === "plain") {
                                            textContent = textToUrl(content)
                                        }
                                        return (
                                            <div data-deleted={deleted} key={messageId} className={`message-item ${deleted ? 'deleted' : ''}`}
                                                 data-sender={sender}
                                                 data-messagetype={messageType}>
                                                {sender === "dashboard" && (
                                                    <div
                                                        className={`more-tooltip ${showMoreToolTip === messageId ? 'show' : ''}`}>
                                                        <Popover visible={showMoreToolTip === messageId}
                                                                 destroyTooltipOnHide={true}
                                                                 zIndex={0} overlayClassName="chat-message-item-popover"
                                                                 placement="bottomRight" title={''}
                                                                 onVisibleChange={(visible) => {
                                                                     if (!visible) {
                                                                         setState(prevState => {
                                                                             return {
                                                                                 ...prevState,
                                                                                 showMoreToolTip: null
                                                                             }
                                                                         })
                                                                     } else {
                                                                         setState(prevState => {
                                                                             return {
                                                                                 ...prevState,
                                                                                 showMoreToolTip: messageId
                                                                             }
                                                                         })
                                                                     }
                                                                 }} content={(
                                                            <div className="more-tooltip-box">
                                                                <div onClick={() => {
                                                                    setState(prevState => {
                                                                        return {
                                                                            ...prevState,
                                                                            showMoreToolTip: null
                                                                        }
                                                                    })
                                                                    return showDeleteConfirm(messageId, item, key)
                                                                }}>Remove
                                                                </div>
                                                                <div onClick={() => {
                                                                    setState(prevState => {
                                                                        return {
                                                                            ...prevState,
                                                                            showMoreToolTip: null,
                                                                            recipientsModalProps: {
                                                                                visible: true,
                                                                                messageId: messageId
                                                                            }
                                                                        }
                                                                    })
                                                                }}>Recipients
                                                                </div>
                                                            </div>
                                                        )} trigger="click">
                                                            <div className="more-tooltip-btn">
                                                                <span/>
                                                                <span/>
                                                                <span/>
                                                            </div>
                                                        </Popover>
                                                    </div>
                                                )}
                                                {messageType === 'plain' && (
                                                    <div className="content"
                                                         data-status={seenIndex === messageId ? ('Seen') : sentIndex === messageId ? ('Sent') : ''}
                                                         dangerouslySetInnerHTML={{__html: textContent.replace(/(\r\n|\n|\r)/gm, "<br />")}}/>
                                                )}
                                                {messageType === 'image' && (
                                                    <div className="content"
                                                         data-status={seenIndex === messageId ? ('Seen') : sentIndex === messageId ? ('Sent') : ''}>
                                                        <Image preview={true} src={content} style={{width: '100%'}}/>
                                                    </div>
                                                )}

                                                {messageType === 'button' && (
                                                    <div className="content"
                                                         data-status={seenIndex === messageId ? ('Seen') : sentIndex === messageId ? ('Sent') : ''}>
                                                        <div className="text">{contentOBJ.data}</div>
                                                        <div className="icon"><ArrowRightIcon/></div>
                                                    </div>
                                                )}
                                            </div>
                                        )
                                    })}
                                    {messageItemGroup.length ? (
                                        <div key={`${index}-${item}`} className="date-separator">{getMyText(item)}</div>
                                    ) : ''}
                                </React.Fragment>
                            )

                        })}
                    </InfiniteScroll>
                </div>
                <ChatFooter conversationId={threadData.id}/>

                {showRecipientsModal && (
                    <RecipientsList visible={showRecipientsModal} messageId={recipientsModalProps.messageId}
                                    handleSuccess={({flag, message}) => {
                                        if (flag) {
                                            return successMessage(message)
                                        }
                                    }} handleClose={flag => {
                        if (flag) {
                            setState(prevState => {
                                return {
                                    ...prevState,
                                    recipientsModalProps: {
                                        visible: false,
                                        messageId: null
                                    }
                                }
                            })
                        }
                    }}/>
                )}
            </>
        </div>
    )
}

export default ChatBox;
