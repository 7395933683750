/* eslint-disable no-template-curly-in-string */
import React, {useEffect, useState} from 'react';
import {authHeader, errorMessage, successMessage} from "../../../_helpers";
import axios from "axios";
import Spin from "antd/lib/spin";
import InfiniteScroll from "react-infinite-scroll-component";
import Input from 'antd/lib/input';
import {ReactComponent as RemoveIcon} from "../../../Assets/svg/icon-trash.svg";
import {ReactComponent as EditIcon} from "../../../Assets/svg/icon-edit.svg";
import {ReactComponent as SendIcon} from "../../../Assets/svg/icon-send.svg";
import {ExclamationCircleOutlined} from "@ant-design/icons";
import Modal from "antd/lib/modal";
import Button from "antd/lib/button";
import TemplateTypeText from "../templateTypes/text"
import {useSelector} from "react-redux";
import Popover from "antd/lib/popover";

const {confirm} = Modal;
const {Search} = Input;
const apiUrl = process.env.REACT_APP_API;

const TemplateTextList = (props) => {
    const threadData = useSelector(globalState => globalState.threadData)

    const [state, setState] = useState({
        updateList: props.updateList,
        hasMore: true,
        listReady: false,
        loader: true,
        searchValue: '',
        pp: 50,
        list: {},
        listTotal: 0,
        activeIndex: null,
        showModal: false,
        editableTemplateData: null,
        editableTemplateTextFieldData: ''
    })

    useEffect(() => {
        if (props.updateList && props.updateList !== state.updateList) {
            setState(prevState => {
                return {
                    ...prevState,
                    updateList: props.updateList
                }
            })
            getList().then(res => {
                let {code, payload, message} = res

                if (code === 200) {
                    setState(prevState => {
                        return {
                            ...prevState,
                            listReady: true,
                            listTotal: payload.count,
                            list: payload.list
                        }
                    })
                } else {
                    return errorMessage(message)
                }
            })
        }
        return () => {
        }
    }, [props.updateList, state.updateList])

    useEffect(() => {
        getList().then(res => {
            let {code, payload, message} = res

            if (code === 200) {
                setState(prevState => {
                    return {
                        ...prevState,
                        listReady: true,
                        listTotal: payload.count,
                        list: payload.list
                    }
                })
            } else {
                return errorMessage(message)
            }
        })


        return () => {
        }
    }, [])

    const getList = async (start = 0, end = 50, search = "") => {
        setState(prevState => {
            return {
                ...prevState,
                loader: true
            }
        })
        const queryParams = {
            _start: start,
            _end: end,
            search,
            type: 'text'
        }
        let res = await axios.get(`${apiUrl}/templates/list`, {
            params: queryParams,
            headers: authHeader()
        });

        const {data} = res;

        if (data.error === null) {
            const {count, rows} = data.payload
            setState(prevState => {
                return {
                    ...prevState,
                    loader: false
                }
            })

            return {
                code: 200,
                payload: {
                    count,
                    list: rows,
                }
            }
        } else {
            setState(prevState => {
                return {
                    ...prevState,
                    loader: false
                }
            })

            return {
                code: 400,
                message: data.error.message ? data.error.message : 'Something went wrong!'
            }
        }
    }

    const onSearch = value => {
        const {pp} = state
        setState(prevState => {
            return {
                ...prevState,
                searchValue: value
            }
        })
        getList(0, pp, value).then(res => {
            const {message, code, payload} = res
            if (code === 200) {
                setState(prevState => {
                    return {
                        ...prevState,
                        listTotal: payload.count,
                        list: payload.list
                    }
                })
            } else {
                return errorMessage(message)
            }
        })
    }

    const getMore = async () => {
        let {hasMore, list, pp, listTotal, searchValue} = state

        if (!hasMore) {
            return true
        }

        if (list.length === listTotal) {
            setState(prevState => {
                return {
                    ...prevState,
                    hasMore: false,
                }
            });
            return true
        }

        setState(prevState => {
            return {
                ...prevState,
                loader: true
            }
        });

        const start = list.length,
            end = parseInt(list.length) + pp;

        getList(start, end, searchValue).then(res => {
            const {message, code, payload} = res
            if (code === 200) {
                list = list.concat(payload.list)
                setState(prevState => {
                    return {
                        ...prevState,
                        loader: false,
                        list,
                    }
                })
            } else {
                return errorMessage(message)
            }
        })
    }

    const deleteTemplate = async (id) => {

        let res = await axios.delete(`${apiUrl}/templates/delete/${id}`, {
            headers: authHeader()
        });

        let {data} = res

        if (data.error === null) {
            return {
                code: 200,
                message: 'Template successfully deleted!'
            }
        } else {
            return {
                code: 400,
                message: data.error.message ? data.error.message : 'Something went wrong!'
            }
        }
    }

    const showDeleteConfirm = (templateId, templateIndex) => {

        return new Promise((resolve, reject) => {
            confirm({
                title: 'Are you sure delete this Thread?',
                icon: <ExclamationCircleOutlined/>,
                okText: 'Yes',
                okType: 'danger',
                cancelText: 'No',
                className: "ant-modal-thread-delete-confirm",
                onOk() {
                    deleteTemplate(templateId, templateIndex).then(res => {
                        const {code, message} = res
                        if (code === 200) {
                            let {list} = state
                            list.splice(templateIndex, 1)
                            setState(prevState => {
                                return {
                                    ...prevState,
                                    list
                                }
                            })
                            successMessage(message, 1).then(() => {
                                return resolve(true)
                            })
                        } else {
                            errorMessage(message).then(() => {
                                return reject(message)
                            })
                        }
                    })
                }
            });
        })
    }

    const getDataByTemplateId = async (id) => {
        const queryParams = {
            templateId: id
        }
        let res = await axios.get(`${apiUrl}/templates/get`, {
            params: queryParams,
            headers: authHeader()
        });

        const {data} = res;

        if (data.error === null) {
            return {
                code: 200,
                payload: data.payload
            }
        } else {
            return {
                code: 400,
                message: data.error.message ? data.error.message : 'Something went wrong!'
            }
        }
    }

    const updateTemplate = async () => {
        let {editableTemplateTextFieldData, editableTemplateData} = state
        if (!editableTemplateTextFieldData) {
            setState(prevState => {
                return {
                    ...prevState,
                    error: true
                }
            })
            return false
        }

        let postData = {
            data: editableTemplateTextFieldData,
            type: "text"
        }
        let res = await axios.put(`${apiUrl}/templates/edit/${editableTemplateData.id}`, postData, {
            headers: authHeader()
        });

        const {data} = res;

        if (data.error === null) {
            setState(prevState => {
                return {
                    ...prevState,
                    showModal: false
                }
            })
            getList().then(res => {
                let {code, payload, message} = res

                if (code === 200) {
                    setState(prevState => {
                        return {
                            ...prevState,
                            listReady: true,
                            listTotal: payload.count,
                            list: payload.list
                        }
                    })
                } else {
                    return errorMessage(message)
                }
            })
            return successMessage(data.payload.message)
        } else {
            return errorMessage(data.error.message)
        }
    }

    const handleData = data => {
        setState(prevState => {
            return {
                ...prevState,
                error: false,
                editableTemplateTextFieldData: data.data
            }
        })
    }

    const sendMsg = async (data) => {
        if (!threadData.id) {
            return errorMessage('Before send message open a thread')
        }
        if (threadData.deleted) {
            return errorMessage('This Thread is deleted!')
        }
        let chatInputField = document.getElementById('chat-input')
        chatInputField.value = data.data
        chatInputField.style.cssText = `height:${chatInputField.scrollHeight}px`;
    }

    const {list, listReady, loader, activeIndex, hasMore, showModal, editableTemplateData, error} = state

    return (
        <div className="template-text-list-box">
            <div className="search-box">
                <div className="field-box">
                    <Search
                        autoComplete={new Date().valueOf()}
                        placeholder="Search"
                        onSearch={onSearch}
                    />
                </div>
            </div>
            <div id="textTemplateScrollableDiv" className="text-list-box template-scrollable-box"
                 style={{height: "100%", overflowY: "scroll"}}>
                {loader && (
                    <div id="Loader" className="loading-box">
                        <Spin size="large"/>
                    </div>
                )}
                {listReady && (
                    <>
                        <InfiniteScroll
                            dataLength={Object.keys(list).length}
                            next={getMore}
                            hasMore={hasMore}
                            scrollableTarget="textTemplateScrollableDiv"
                            loader={list.length ? <Spin size="large"/> : ''}
                        >
                            {list.map((item, key) => {
                                const {id, data} = item

                                return (
                                    <Popover destroyTooltipOnHide={true} key={key}
                                             overlayClassName="template-action-box-popover"
                                             placement="bottomRight" visible={activeIndex === id} title={''}
                                             onVisibleChange={(visible) => {
                                                 if (!visible) {
                                                     setState(prevState => {
                                                         return {
                                                             ...prevState,
                                                             activeIndex: null
                                                         }
                                                     })
                                                 } else {
                                                     setState(prevState => {
                                                         return {
                                                             ...prevState,
                                                             activeIndex: id
                                                         }
                                                     })
                                                 }
                                             }} content={(
                                        <>
                                            <div className="action-box">
                                                <div className="remove" onClick={() => {
                                                    setState(prevState => {
                                                        return {
                                                            ...prevState,
                                                            activeIndex: null
                                                        }
                                                    })
                                                    return showDeleteConfirm(id, key)
                                                }}>
                                                    <RemoveIcon/>
                                                </div>

                                                <div className="edit" onClick={() => {
                                                    setState(prevState => {
                                                        return {
                                                            ...prevState,
                                                            activeIndex: null
                                                        }
                                                    })
                                                    getDataByTemplateId(id).then(res => {
                                                        let {code, payload} = res
                                                        if (code === 200) {
                                                            setState(prevState => {
                                                                return {
                                                                    ...prevState,
                                                                    editableTemplateData: payload,
                                                                    editableTemplateTextFieldData: payload.data,
                                                                    showModal: true
                                                                }
                                                            });
                                                        }
                                                    })
                                                }}>
                                                    <EditIcon/>
                                                </div>

                                                <div className="send" onClick={() => {
                                                    setState(prevState => {
                                                        return {
                                                            ...prevState,
                                                            activeIndex: null
                                                        }
                                                    })
                                                    return sendMsg(item)
                                                }}>
                                                    <SendIcon/>
                                                </div>
                                            </div>
                                        </>
                                    )} trigger="click">
                                        <div
                                            className={`text-list-item ${activeIndex === id ? "active" : ""}`}>
                                            <div className="text">{data}</div>
                                        </div>
                                    </Popover>

                                )
                            })}
                        </InfiniteScroll>

                        {!Object.keys(list).length && (
                            <div className="no-data">No Text Templates</div>
                        )}
                    </>
                )}
            </div>

            <Modal
                centered
                closable={false}
                maskClosable={false}
                visible={showModal}
                className="ant-modal-create-template"
                afterClose={() => {
                    setState(prevState => {
                        return {
                            ...prevState,
                            editableTemplateData: null
                        }
                    });
                }}
                footer={
                    [
                        <Button key="cancel" type="primary-bordered" onClick={() => {
                            setState(prevState => {
                                return {
                                    ...prevState,
                                    showModal: false
                                }
                            });
                        }}>
                            Cancel
                        </Button>,
                        <Button key="save" type="primary" onClick={() => {
                            return updateTemplate()
                        }}>
                            Update
                        </Button>
                    ]
                }
            >
                {
                    loader ? (
                        <div id="Loader" className="loading-box">
                            <Spin size="large"/>
                        </div>
                    ) : null
                }

                <div className="ant-modal-title">
                    Edit Template
                </div>
                <div className="create-template-box">
                    <TemplateTypeText data={editableTemplateData} handleData={handleData} error={error}/>
                </div>
            </Modal>
        </div>
    )
}

export default TemplateTextList;
