import React, {useEffect} from "react";
import TemplatesComponent from "./components/templates";
import ChatComponent from "./components/chat";
import socket from "./socketConfig";
import {useDispatch, useSelector} from "react-redux";
import {newMessageAction, threadRemovedAction, newThreadAction, updateRemovedAction} from "./actions/index"

const MainWrapper = () => {
    const dispatch = useDispatch()
    const threadData = useSelector(globalState => globalState.threadData)

    useEffect(() => {
        socket.on('message', (data) => {
            dispatch(newMessageAction(data))
        })
        socket.on('threadRemoved', (data) => {
            dispatch(threadRemovedAction(data))
        })
        socket.on('newThread', (data) => {
            dispatch(newThreadAction(data))
        })
        socket.on('updateThread', (data) => {
            dispatch(updateRemovedAction(data))
        })
        return () => {
            socket.off("message");
            socket.off("threadRemoved");
            socket.off("newThread");
            socket.off("updateThread");
        }
    }, [dispatch, threadData])
    return (
        <div className="main-wrapper">
            <ChatComponent/>
            <TemplatesComponent/>
        </div>
    )
}

export default MainWrapper;
