/* eslint-disable no-template-curly-in-string */
import React, {useState, useEffect} from 'react';
import {useDispatch} from 'react-redux'
import {setLocalStorage} from "../../_helpers";
import Form from 'antd/lib/form';
import Input from 'antd/lib/input';
import Button from 'antd/lib/button';
import Spin from 'antd/lib/spin';
import uuid from "react-uuid";
import axios from "axios";
import {signin} from '../../actions'

const apiUrl = process.env.REACT_APP_API;

const Login = () => {
    const dispatch = useDispatch()
    const [form] = Form.useForm();
    const [ErrorMessage, setErrorMessage] = useState('');
    const [loaderState, showLoader] = useState(false);

    useEffect(() => {
        document.body.className = 'page-login';
        return () => {
            document.body.className = '';
        }
    }, []);

    const onFinish = async v => {
        let {password, email} = v
        showLoader(true)
        let deviceId = uuid();

        let postData = {
            password,
            email,
            deviceId
        };
        let res = await axios.post(`${apiUrl}/adminusers/login`, postData);
        let {data} = res;
        if (data.error === null) {
            showLoader(false)
            window.location = '/'
            dispatch(signin(data.payload))
            setLocalStorage('deviceId', deviceId)
        } else {
            showLoader(false)
            data.error.message ? setErrorMessage(data.error.message) : setErrorMessage('Something went wrong')
        }
    };

    const onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
    };

    const validateMessages = {
        required: '${label} is required!',
        types: {
            email: '${label} is not validate email!'
        },
    };

    return (
        <>
            {
                loaderState ? (
                    <div id="loader" className="loading-box">
                        <Spin size="large"/>
                    </div>
                ) : ''
            }

            <div className="login-content">
                <Form
                    form={form}
                    name="login_form"
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    validateMessages={validateMessages}
                >
                    <div className="form-box">
                        <div className="logo">
                            <svg xmlns="http://www.w3.org/2000/svg" width="104.598" height="42.102"
                                 viewBox="0 0 104.598 42.102">
                                <g transform="translate(-677.459 2425.383)">
                                    <path
                                        d="M711.43-2418.231l-10.254-5.945a8.944,8.944,0,0,0-8.976,0l-10.254,5.945a9.019,9.019,0,0,0-4.488,7.806v11.889a9.019,9.019,0,0,0,4.488,7.805l10.254,5.945a8.942,8.942,0,0,0,8.976,0l10.254-5.945a9.019,9.019,0,0,0,4.488-7.805v-11.889A9.019,9.019,0,0,0,711.43-2418.231Zm-6.515,23.79a13.414,13.414,0,0,1-4.913.929c-7.738,0-14.034-6.619-14.034-14.753a15.477,15.477,0,0,1,.623-4.361,3.917,3.917,0,0,1,4.856-2.664l.011,0a3.938,3.938,0,0,1,2.65,4.888,7.553,7.553,0,0,0-.3,2.133c0,3.8,2.78,6.884,6.2,6.884a5.6,5.6,0,0,0,2.059-.39,3.913,3.913,0,0,1,5.07,2.217l.006.016a3.941,3.941,0,0,1-2.223,5.1Z"
                                        transform="translate(0 0)" fill="#00c0e7"/>
                                    <g transform="translate(719.772 -2389.899)">
                                        <path
                                            d="M781.676-2337.622a2.873,2.873,0,0,1,0,.506,2.586,2.586,0,0,1-.1.535,2.524,2.524,0,0,1-.218.513,1.673,1.673,0,0,1-.35.434,1.606,1.606,0,0,1-.5.3,1.829,1.829,0,0,1-.669.113,2.424,2.424,0,0,1-.324-.023,3.013,3.013,0,0,1-.331-.063,2.86,2.86,0,0,1-.315-.1,1.827,1.827,0,0,1-.277-.135l.559-5.029h.562l-.185,1.7c-.014.113-.028.233-.042.358s-.029.236-.044.333h.007a2.2,2.2,0,0,1,.2-.319,1.247,1.247,0,0,1,.239-.243.994.994,0,0,1,.287-.154,1.044,1.044,0,0,1,.345-.055,1,1,0,0,1,.795.348A1.719,1.719,0,0,1,781.676-2337.622Zm-.568.015a1.7,1.7,0,0,0-.07-.358.859.859,0,0,0-.138-.271.576.576,0,0,0-.211-.17.677.677,0,0,0-.294-.059.684.684,0,0,0-.4.128,1.266,1.266,0,0,0-.326.34,2.072,2.072,0,0,0-.236.479,2.4,2.4,0,0,0-.12.546l-.12,1.107a1.294,1.294,0,0,0,.3.111,1.5,1.5,0,0,0,.365.044,1.1,1.1,0,0,0,.433-.081,1.076,1.076,0,0,0,.338-.224,1.352,1.352,0,0,0,.246-.33,1.874,1.874,0,0,0,.158-.4,2.312,2.312,0,0,0,.075-.434A2.522,2.522,0,0,0,781.108-2337.607Z"
                                            transform="translate(-778.592 2340.573)" fill="#1a1818"/>
                                        <path
                                            d="M788.012-2332.594a3.352,3.352,0,0,1-.271.433,1.749,1.749,0,0,1-.3.318,1.173,1.173,0,0,1-.353.2,1.3,1.3,0,0,1-.429.067,1.166,1.166,0,0,1-.176-.015,1.35,1.35,0,0,1-.157-.031l.057-.473a.569.569,0,0,0,.136.043.889.889,0,0,0,.161.016.693.693,0,0,0,.41-.124,1.109,1.109,0,0,0,.315-.379l.353-.62-.971-3.327h.576l.667,2.564a1.325,1.325,0,0,1,.026.139.8.8,0,0,1,.007.116h.02c.009-.026.022-.063.042-.111a1,1,0,0,1,.066-.136l1.275-2.571h.614Z"
                                            transform="translate(-783.09 2338.198)" fill="#1a1818"/>
                                        <path
                                            d="M802.648-2334.913l-.355-1.393h-2.061l-.652,1.393h-.671l2.415-4.98h.6l1.328,4.98Zm-1.013-4.039c0-.023-.009-.05-.012-.08s-.008-.06-.011-.091l-.009-.09-.007-.076h-.014c0,.021-.011.045-.02.074s-.018.06-.028.091-.022.062-.033.092-.023.057-.031.08l-1,2.118h1.678Z"
                                            transform="translate(-790.409 2340.177)" fill="#1a1818"/>
                                        <path
                                            d="M811.716-2334.913h-.569l-1.209-4.98h.59l.911,3.9a2,2,0,0,1,.045.221,1.384,1.384,0,0,1,.017.214h.014c.008-.031.018-.064.029-.1s.025-.074.04-.113l.046-.114.042-.107,1.8-3.9h.654Z"
                                            transform="translate(-796.822 2340.177)" fill="#1a1818"/>
                                        <path
                                            d="M824.444-2339.365h-1.383l-.486,4.453h-.583l.493-4.453h-1.413l.058-.528h3.376Z"
                                            transform="translate(-803.298 2340.177)" fill="#1a1818"/>
                                        <path
                                            d="M838.3-2334.913h-.535l.349-3.342q.02-.2.058-.45t.092-.54h-.013q-.031.083-.079.186c-.032.068-.066.135-.1.2s-.069.121-.1.172-.05.089-.063.115l-1.428,2.361h-.262l-.931-2.354a1.09,1.09,0,0,0-.042-.12c-.021-.052-.042-.11-.062-.174s-.04-.129-.058-.2a1.083,1.083,0,0,1-.035-.188h-.013q-.006.149-.026.4t-.053.593l-.35,3.334h-.518l.52-4.98h.641l.942,2.369q.063.156.1.291a1.919,1.919,0,0,1,.055.236h.019c.028-.07.062-.151.1-.243a2.351,2.351,0,0,1,.162-.3l1.435-2.354h.711Z"
                                            transform="translate(-810.896 2340.177)" fill="#1a1818"/>
                                        <path
                                            d="M849.864-2335.294a2.936,2.936,0,0,1-.044.9,2.068,2.068,0,0,1-.3.741,1.583,1.583,0,0,1-.548.5,1.558,1.558,0,0,1-.773.186,1.485,1.485,0,0,1-.547-.1,1.269,1.269,0,0,1-.433-.28,1.41,1.41,0,0,1-.3-.44,1.887,1.887,0,0,1-.146-.585,2.889,2.889,0,0,1,.049-.916,2.1,2.1,0,0,1,.315-.74,1.588,1.588,0,0,1,.549-.493,1.557,1.557,0,0,1,.751-.178,1.469,1.469,0,0,1,.543.1,1.279,1.279,0,0,1,.436.28,1.438,1.438,0,0,1,.3.441A1.813,1.813,0,0,1,849.864-2335.294Zm-.566.028a1.426,1.426,0,0,0-.093-.4.965.965,0,0,0-.181-.3.755.755,0,0,0-.254-.184.778.778,0,0,0-.318-.064.9.9,0,0,0-.524.156,1.205,1.205,0,0,0-.367.411,1.878,1.878,0,0,0-.2.58,2.434,2.434,0,0,0-.026.665,1.422,1.422,0,0,0,.09.391,1,1,0,0,0,.178.3.733.733,0,0,0,.256.189.784.784,0,0,0,.323.066.909.909,0,0,0,.528-.155,1.172,1.172,0,0,0,.367-.41,1.83,1.83,0,0,0,.2-.581A2.515,2.515,0,0,0,849.3-2335.266Z"
                                            transform="translate(-818.234 2338.319)" fill="#1a1818"/>
                                        <path
                                            d="M859.248-2337.622a2.855,2.855,0,0,1,0,.506,2.59,2.59,0,0,1-.1.535,2.521,2.521,0,0,1-.218.513,1.672,1.672,0,0,1-.35.434,1.607,1.607,0,0,1-.5.3,1.83,1.83,0,0,1-.669.113,2.424,2.424,0,0,1-.324-.023,3.009,3.009,0,0,1-.331-.063,2.843,2.843,0,0,1-.315-.1,1.819,1.819,0,0,1-.277-.135l.559-5.029h.562l-.185,1.7c-.014.113-.028.233-.042.358s-.028.236-.044.333h.007a2.2,2.2,0,0,1,.2-.319,1.248,1.248,0,0,1,.239-.243,1,1,0,0,1,.287-.154,1.044,1.044,0,0,1,.345-.055,1,1,0,0,1,.795.348A1.719,1.719,0,0,1,859.248-2337.622Zm-.568.015a1.714,1.714,0,0,0-.07-.358.861.861,0,0,0-.138-.271.577.577,0,0,0-.211-.17.676.676,0,0,0-.294-.059.684.684,0,0,0-.4.128,1.268,1.268,0,0,0-.326.34,2.067,2.067,0,0,0-.236.479,2.4,2.4,0,0,0-.12.546l-.12,1.107a1.294,1.294,0,0,0,.3.111,1.5,1.5,0,0,0,.365.044,1.1,1.1,0,0,0,.433-.081,1.077,1.077,0,0,0,.338-.224,1.357,1.357,0,0,0,.246-.33,1.883,1.883,0,0,0,.158-.4,2.327,2.327,0,0,0,.075-.434A2.509,2.509,0,0,0,858.68-2337.607Z"
                                            transform="translate(-823.708 2340.573)" fill="#1a1818"/>
                                        <path
                                            d="M865.458-2335.06l.44-3.643h.563l-.442,3.643Zm1.223-4.725a.35.35,0,0,1-.077.267.3.3,0,0,1-.245.111.394.394,0,0,1-.27-.11.4.4,0,0,1-.136-.269.311.311,0,0,1,.085-.261.339.339,0,0,1,.25-.1.387.387,0,0,1,.264.1A.384.384,0,0,1,866.681-2339.785Z"
                                            transform="translate(-829.114 2340.325)" fill="#1a1818"/>
                                        <path d="M869.532-2335.308l.636-5.266h.563l-.638,5.266Z"
                                              transform="translate(-831.483 2340.573)" fill="#1a1818"/>
                                        <path
                                            d="M876.564-2335.777a1.026,1.026,0,0,1-.073.512.892.892,0,0,1-.365.4,2.186,2.186,0,0,1-.718.256,6.12,6.12,0,0,1-1.13.094.452.452,0,0,0,0,.08l.007.069a1.284,1.284,0,0,0,.085.354.917.917,0,0,0,.186.292.848.848,0,0,0,.294.2,1.049,1.049,0,0,0,.406.073,1.576,1.576,0,0,0,.5-.092,1.969,1.969,0,0,0,.509-.262l-.051.563a1.973,1.973,0,0,1-.426.195,1.784,1.784,0,0,1-.568.083,1.613,1.613,0,0,1-.658-.121,1.3,1.3,0,0,1-.455-.325,1.424,1.424,0,0,1-.277-.469,2.243,2.243,0,0,1-.125-.557,2.6,2.6,0,0,1,.01-.6,2.279,2.279,0,0,1,.139-.54,2.015,2.015,0,0,1,.253-.457,1.694,1.694,0,0,1,.349-.353,1.579,1.579,0,0,1,.429-.226,1.509,1.509,0,0,1,.491-.079,1.471,1.471,0,0,1,.479.073,1.1,1.1,0,0,1,.362.2.974.974,0,0,1,.236.293A.953.953,0,0,1,876.564-2335.777Zm-.563.028a.453.453,0,0,0-.191-.34.718.718,0,0,0-.43-.122.863.863,0,0,0-.391.092,1.125,1.125,0,0,0-.332.253,1.376,1.376,0,0,0-.244.385,1.686,1.686,0,0,0-.126.485,5.6,5.6,0,0,0,.819-.051,1.937,1.937,0,0,0,.538-.148.645.645,0,0,0,.289-.237A.509.509,0,0,0,876-2335.749Z"
                                            transform="translate(-833.906 2338.319)" fill="#1a1818"/>
                                    </g>
                                    <path
                                        d="M785.651-2392.372h-2.474a5.628,5.628,0,0,0-3.025.879.06.06,0,0,1-.093-.051v-2.253a1.324,1.324,0,0,0-1.208-1.347,1.276,1.276,0,0,0-1.345,1.274v9.556a5.669,5.669,0,0,0,5.669,5.669h2.474a5.669,5.669,0,0,0,5.669-5.669v-2.389A5.669,5.669,0,0,0,785.651-2392.372Zm3.117,8.058a3.12,3.12,0,0,1-3.117,3.117h-2.474a3.121,3.121,0,0,1-3.117-3.117v-2.389a3.121,3.121,0,0,1,3.117-3.117h2.474a3.121,3.121,0,0,1,3.117,3.117Zm21.38-6.782v11.232a1.276,1.276,0,0,1-1.276,1.276,1.276,1.276,0,0,1-1.251-1.023,5.586,5.586,0,0,1-3.143.966h-2.474a5.71,5.71,0,0,1-5.669-5.739v-6.712a1.276,1.276,0,0,1,1.276-1.276,1.276,1.276,0,0,1,1.276,1.276v6.712a3.156,3.156,0,0,0,3.117,3.187h2.474a3.156,3.156,0,0,0,3.117-3.187v-6.712a1.276,1.276,0,0,1,1.276-1.276A1.276,1.276,0,0,1,810.148-2391.1Zm9.373-1.276h-2.474a5.711,5.711,0,0,0-5.67,5.739v2.249a5.711,5.711,0,0,0,5.67,5.739h2.474a5.679,5.679,0,0,0,4.848-2.761,1.318,1.318,0,0,0-.217-1.687,1.277,1.277,0,0,0-1.943.326,3.121,3.121,0,0,1-2.687,1.571h-2.474a3.15,3.15,0,0,1-3.108-2.945.085.085,0,0,1,.085-.09h10.162a1,1,0,0,0,1-1v-1.4A5.711,5.711,0,0,0,819.522-2392.372Zm3.023,5.588h-8.521a.086.086,0,0,1-.085-.091,3.15,3.15,0,0,1,3.108-2.945h2.474a3.15,3.15,0,0,1,3.108,2.945A.086.086,0,0,1,822.545-2386.784Zm12.032-5.588H832.1a5.711,5.711,0,0,0-5.67,5.739v2.249a5.71,5.71,0,0,0,5.67,5.739h2.474a5.679,5.679,0,0,0,4.847-2.761,1.318,1.318,0,0,0-.217-1.687,1.277,1.277,0,0,0-1.943.326,3.121,3.121,0,0,1-2.687,1.571H832.1a3.151,3.151,0,0,1-3.109-2.945.086.086,0,0,1,.085-.09h10.161a1,1,0,0,0,1-1v-1.4A5.711,5.711,0,0,0,834.577-2392.372Zm3.023,5.588H829.08a.086.086,0,0,1-.085-.091,3.15,3.15,0,0,1,3.109-2.945h2.474a3.15,3.15,0,0,1,3.108,2.945A.086.086,0,0,1,837.6-2386.784Zm-42.5-7.085v14.006a1.276,1.276,0,0,1-1.276,1.276,1.276,1.276,0,0,1-1.276-1.276v-14.006a1.276,1.276,0,0,1,1.276-1.276A1.276,1.276,0,0,1,795.1-2393.869Z"
                                        transform="translate(-58.189 -17.587)" fill="#00c0e7"/>
                                </g>
                            </svg>
                        </div>

                        <Form.Item
                            name="email"
                            label="Email"
                            rules={[{type: 'email', required: true}]}
                        >
                            <Input
                                placeholder="Email"
                                maxLength={320}
                            />
                        </Form.Item>

                        <Form.Item
                            name="password"
                            label="Password"
                            rules={[{required: true}]}
                            className="password-filed-box"
                        >
                            <Input.Password
                                visibilityToggle={false}
                                placeholder="Password"
                            />
                        </Form.Item>

                        <div className="response-box">
                            {ErrorMessage}
                        </div>

                        <Form.Item shouldUpdate={true}>
                            {() => (
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    disabled={
                                        !form.isFieldsTouched(true) || form.getFieldsError().filter(({errors}) => errors.length).length
                                    }
                                >
                                    Log In
                                </Button>
                            )}
                        </Form.Item>
                    </div>
                </Form>
            </div>
        </>
    )
}

export default Login;
