/* eslint-disable no-template-curly-in-string */
import React, {useState} from 'react';
import Sidebar from "./sidebar.js";
import ChatBox from "./chat.js"
import {useSelector} from "react-redux";
import {ReactComponent as Logo} from "../../Assets/svg/logo.svg";

const ChatComponent = () => {
    const threadData = useSelector(globalState => globalState.threadData)

    const [state, setState] = useState({
        threadData: null,
        conversationId: null,
    })

    let {conversationId} = state

    return (

        <>
            <div className="global-wrapper-chat">
                <Sidebar conversationId={(data) => {
                    setState(prevState => {
                        return {
                            ...prevState,
                            conversationId: data
                        }
                    })
                }}/>
                {threadData && (conversationId === threadData.id) ? (
                    <ChatBox />
                ):(
                    <div className="welcome-view">
                        <div className="welcome-view-wrap">
                            <Logo/>
                            <div className="text">Welcome to Chat Dashboard</div>
                        </div>
                    </div>
                )}
            </div>
        </>
    )
}

export default ChatComponent;
