/* eslint-disable no-template-curly-in-string */
import React, {useEffect} from 'react';
import Form from 'antd/lib/form';
import Input from "antd/lib/input";

const TemplateTypeText = (props) => {
    let {error, data} = props
    const [textForm] = Form.useForm();
    const validateMessages = {
        required: '${label} is required!',
    };

    useEffect(() => {
        if (error) {
            textForm.setFields([
                {
                    name: 'text',
                    errors: ['Text is required!'],
                },
            ]);
        }
        return () => {
        }
    }, [error, textForm])

    useEffect(() => {
        if (data) {
            textForm.setFieldsValue({
                text: data.data
            });
        }
        return () => {
        }
    }, [data, textForm])

    return (
        <Form
            form={textForm}
            name="crate-template-text-form"
            validateMessages={validateMessages}
        >
            <div className="field-row">
                <Form.Item
                    name="text"
                    label="Text"
                    rules={[{required: true}]}
                >
                    <Input placeholder="Enter Text for Template" onChange={() => {

                        if (textForm.getFieldValue('text')) {
                            textForm.setFields([
                                {
                                    name: 'text',
                                    validating: true,
                                    errors: null,
                                }
                            ]);
                        }
                        props.handleData({data: textForm.getFieldValue('text')})
                    }}/>
                </Form.Item>
            </div>
        </Form>
    )
}

export default TemplateTypeText;
