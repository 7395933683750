/* eslint-disable no-template-curly-in-string */
import React, {useEffect, useRef, useState} from 'react';
import {ReactComponent as SettingsGear} from "../../Assets/svg/icon-settings.svg";
import Modal from "antd/lib/modal";
import {ExclamationCircleOutlined} from '@ant-design/icons';
import axios from "axios";
import {authHeader, errorMessage, successMessage} from "../../_helpers";
import {useSelector} from "react-redux";
import ThreadEdit from "../thread/edit/index"

const apiUrl = process.env.REACT_APP_API;
const {confirm} = Modal;
const ChatHeader = () => {
    let toolTipRef = useRef()
    const threadData = useSelector(globalState => globalState.threadData)

    let [state, setState] = useState({
        showSettingsToolTip: false,
        showModal: false,
        editThread: false,
        modalType: null
    })
    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        }
    })
    const onSelect = key => {
        setState(prevState => {
            return {
                ...prevState,
                showSettingsToolTip: false,
            }
        })
    };

    const handleClickOutside = event => {
        if (toolTipRef.current && !toolTipRef.current.contains(event.target) && !event.target.classList.contains("user-tooltip-btn")) {
            setState(prevState => {
                return {
                    ...prevState,
                    showSettingsToolTip: false
                }
            })
        }
    }

    const deleteThreads = async () => {
        if(threadData.deleted) {
            return errorMessage('This Thread is deleted!')
        }
        let queryParams = {
            threadId: threadData.id
        }
        let res = await axios.delete(`${apiUrl}/threads/delete`, {
            params: queryParams,
            headers: authHeader()
        });

        let {data} = res

        if (data.error === null) {
            return {
                code: 200,
                threadId: threadData.id,
                message: 'Thread successfully deleted!'
            }
        } else {
            return {
                code: 400,
                message: data.error.message ? data.error.message : 'Something went wrong!'
            }
        }
    }

    const showDeleteConfirm = () => {
        return new Promise((resolve, reject) => {
            confirm({
                title: 'Are you sure delete this Thread?',
                icon: <ExclamationCircleOutlined/>,
                okText: 'Yes',
                okType: 'danger',
                cancelText: 'No',
                className: "ant-modal-thread-delete-confirm",
                onOk() {
                    deleteThreads().then(res => {
                        const {code, message} = res
                        if (code === 200) {
                            return resolve(true)
                        }
                        return reject(message)
                    })
                }
            });
        })
    }

    if (!threadData) return true;

    const {name, type, userPhoneNumber, parentThreadName} = threadData
    const {showSettingsToolTip, showEditThread} = state

    return (
        <>
            <div className="chat-box-header">
                <div className={`thread-info type-${type}`}>
                    {type === "children" ? (
                        <>
                            <div className="thread-name">
                                {userPhoneNumber.includes("+") ? userPhoneNumber : `+${userPhoneNumber}`}
                            </div>
                            <div className="thread-label">
                                <span>In Thread:</span> <span>{parentThreadName}</span>
                            </div>
                        </>
                    ) : (
                        <div className="thread-name">
                            {name}
                        </div>
                    )}

                </div>

                <div className="thread-settings">
                    <div className="thread-settings-icon">
                        <SettingsGear onClick={() => {
                            setState(prevState => {
                                return {
                                    ...prevState,
                                    showSettingsToolTip: true
                                }
                            })
                        }}/>
                    </div>

                    {
                        showSettingsToolTip ? (
                            <div className="thread-settings-tooltip-box" ref={toolTipRef}>
                                {type === "parent" && (
                                    <div onClick={() => {
                                        onSelect()
                                        if(threadData.deleted) {
                                            return errorMessage('This Thread is deleted!')
                                        }
                                        setState(prevState => {
                                            return {
                                                ...prevState,
                                                showEditThread: true
                                            }
                                        })
                                    }}>
                                        Edit Thread
                                    </div>
                                )}
                                <div onClick={() => {
                                    onSelect()
                                    return showDeleteConfirm()
                                }}>
                                    Delete Thread
                                </div>
                            </div>
                        ) : null
                    }
                </div>
            </div>

            <ThreadEdit visible={showEditThread} handleSuccess={({flag, message}) => {
                if (flag) {
                    return successMessage(message)
                }
            }} handleClose={flag => {
                if (flag) {
                    setState(prevState => {
                        return {
                            ...prevState,
                            showEditThread: false
                        }
                    })
                }
            }}/>


        </>
    )
}

export default ChatHeader;
