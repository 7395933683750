/* eslint-disable no-template-curly-in-string */
import io from 'socket.io-client';
import {getLocalStorage} from "./_helpers";
const socketUrl = process.env.REACT_APP_SOCKET_URL;
const user = getLocalStorage('user', true)

let socket

if(user) {
    socket = io(socketUrl, {
        query: `token=${user.token}&platformType=dashboard`,
        transports: ['websocket']
    });
}

export default socket;
