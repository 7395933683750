/* eslint-disable no-template-curly-in-string */
const newThreadReducer = (state = [], action) => {

    switch (action.type) {
        case 'NEW_THREAD_CREATED':
            return action.data
        default:
            return state
    }
}

export default newThreadReducer;
