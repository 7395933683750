/* eslint-disable no-template-curly-in-string */
import React, {useEffect, useRef, useState} from 'react';
import {authHeader, errorMessage, successMessage} from "../../../_helpers";
import axios from "axios";
import Spin from "antd/lib/spin";
import InfiniteScroll from "react-infinite-scroll-component";
import {ReactComponent as RemoveIcon} from "../../../Assets/svg/icon-trash.svg";
import {ReactComponent as SendIcon} from "../../../Assets/svg/icon-send.svg";
import Image from "antd/lib/image";
import {ExclamationCircleOutlined} from "@ant-design/icons";
import Modal from "antd/lib/modal";
import socket from "../../../socketConfig";
import {useSelector} from "react-redux";

const {confirm} = Modal;
const apiUrl = process.env.REACT_APP_API;
const TemplateImageList = (props) => {
    let toolTipRef = useRef()
    const threadData = useSelector(globalState => globalState.threadData)

    const [state, setState] = useState({
        updateList: props.updateList,
        hasMore: true,
        listReady: false,
        loader: true,
        searchValue: '',
        pp: 50,
        list: {},
        listTotal: 0,
        activeIndex: null
    })

    useEffect(() => {
        if (props.updateList && props.updateList !== state.updateList) {
            setState(prevState => {
                return {
                    ...prevState,
                    updateList: props.updateList
                }
            })
            getList().then(res => {
                let {code, payload, message} = res

                if (code === 200) {
                    setState(prevState => {
                        return {
                            ...prevState,
                            listReady: true,
                            listTotal: payload.count,
                            list: payload.list
                        }
                    })
                } else {
                    return errorMessage(message)
                }
            })
        }
        return () => {
        }
    }, [props.updateList, state.updateList])

    useEffect(() => {
        getList().then(res => {
            let {code, payload, message} = res

            if (code === 200) {
                setState(prevState => {
                    return {
                        ...prevState,
                        listReady: true,
                        listTotal: payload.count,
                        list: payload.list
                    }
                })
            } else {
                return errorMessage(message)
            }
        })

        return () => {
        }
    }, [])

    const getList = async (start = 0, end = 50, search = "") => {
        setState(prevState => {
            return {
                ...prevState,
                loader: true
            }
        })
        const queryParams = {
            _start: start,
            _end: end,
            search,
            type: 'image'
        }

        let res = await axios.get(`${apiUrl}/templates/list`, {
            params: queryParams,
            headers: authHeader()
        });

        const {data} = res;

        if (data.error === null) {
            const {count, rows} = data.payload
            setState(prevState => {
                return {
                    ...prevState,
                    loader: false
                }
            })

            return {
                code: 200,
                payload: {
                    count,
                    list: rows,
                }
            }
        } else {
            setState(prevState => {
                return {
                    ...prevState,
                    loader: false
                }
            })

            return {
                code: 400,
                message: data.error.message ? data.error.message : 'Something went wrong!'
            }
        }
    }

    const getMore = async () => {
        let {hasMore, list, pp, listTotal, searchValue} = state

        if (!hasMore) {
            return true
        }

        if (list.length === listTotal) {
            setState(prevState => {
                return {
                    ...prevState,
                    hasMore: false,
                }
            });
            return true
        }

        setState(prevState => {
            return {
                ...prevState,
                loader: true
            }
        });

        const start = list.length,
            end = parseInt(list.length) + pp;

        getList(start, end, searchValue).then(res => {
            const {message, code, payload} = res
            if (code === 200) {
                list = list.concat(payload.list)
                setState(prevState => {
                    return {
                        ...prevState,
                        loader: false,
                        list,
                    }
                })
            } else {
                return errorMessage(message)
            }
        })
    }

    const deleteTemplate = async (id) => {

        let res = await axios.delete(`${apiUrl}/templates/delete/${id}`, {
            headers: authHeader()
        });

        let {data} = res

        if (data.error === null) {
            return {
                code: 200,
                message: 'Template successfully deleted!'
            }
        } else {
            return {
                code: 400,
                message: data.error.message ? data.error.message : 'Something went wrong!'
            }
        }
    }

    const showDeleteConfirm = (templateId, templateIndex) => {

        return new Promise((resolve, reject) => {
            confirm({
                title: 'Are you sure delete this Thread?',
                icon: <ExclamationCircleOutlined/>,
                okText: 'Yes',
                okType: 'danger',
                cancelText: 'No',
                className: "ant-modal-thread-delete-confirm",
                onOk() {
                    deleteTemplate(templateId, templateIndex).then(res => {
                        const {code, message} = res
                        if (code === 200) {
                            let {list} = state
                            list.splice(templateIndex, 1)
                            setState(prevState => {
                                return {
                                    ...prevState,
                                    list
                                }
                            })
                            successMessage(message, 1).then(() => {
                                return resolve(true)
                            })
                        } else {
                            errorMessage(message).then(() => {
                                return reject(message)
                            })
                        }
                    })
                }
            });
        })
    }

    const sendMsg = async (data) => {
        if (!threadData.id) {
            return errorMessage('Before send message open a thread')
        }
        if (threadData.deleted) {
            return errorMessage('This Thread is deleted!')
        }
        let {id} = threadData
        let {imageUrl} = data
        socket.emit('message', {
            threadId: id,
            messageType: "image",
            content: imageUrl,
            pattern: threadData.parentId ? threadData.userPhoneNumber : ''
        }, (confirmation) => {
            if (confirmation) {

            } else {
                return errorMessage('Something went wrong!')
            }
        })
    }

    const {list, listReady, loader, activeIndex, hasMore} = state

    return (
        <div className="template-image-list-box">

            <div id="imageTemplateScrollableDiv" className="image-list-box template-scrollable-box"
                 style={{height: "100%", overflowY: "scroll"}}>
                {loader && (
                    <div id="Loader" className="loading-box">
                        <Spin size="large"/>
                    </div>
                )}
                {listReady && (
                    <>
                        <InfiniteScroll
                            dataLength={Object.keys(list).length}
                            next={getMore}
                            hasMore={hasMore}
                            style={{
                                display: 'flex',
                                flexWrap: 'wrap',
                                gap: '21px'
                            }}
                            scrollableTarget="imageTemplateScrollableDiv"
                            loader={list.length ? <Spin size="large"/> : ''}
                        >
                            {list.map((item, key) => {
                                const {id, data, imageUrl} = item

                                return (
                                    <div
                                        ref={toolTipRef}
                                        className={`image-list-item ${activeIndex === id ? "active" : ""}`}
                                        key={key}
                                        onClick={() => {
                                            if (activeIndex === id) {
                                                setState(prevState => {
                                                    return {
                                                        ...prevState,
                                                        activeIndex: null
                                                    }
                                                })
                                                return true
                                            }
                                            setState(prevState => {
                                                return {
                                                    ...prevState,
                                                    activeIndex: id
                                                }
                                            })
                                        }}>
                                        <Image preview={false} src={imageUrl} alt={data} style={{width: '100%'}}/>
                                        {activeIndex === id && (
                                            <>
                                                <div className="overlay"/>
                                                <div className="action-box">
                                                    <div className="remove" onClick={() => {
                                                        return showDeleteConfirm(id, key)
                                                    }}>
                                                        <RemoveIcon/>
                                                    </div>

                                                    <div className="send" onClick={() => {
                                                        return sendMsg(item)
                                                    }}>
                                                        <SendIcon/>
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                )
                            })}
                        </InfiniteScroll>

                        {!Object.keys(list).length && (
                            <div className="no-data">No Images Templates</div>
                        )}
                    </>
                )}
            </div>
        </div>
    )
}

export default TemplateImageList;
