/* eslint-disable no-template-curly-in-string */
import React, {useState, useEffect} from 'react';
import Input from 'antd/lib/input';
import {authHeader, errorMessage} from "../../_helpers";
import axios from "axios";
import Spin from "antd/lib/spin";
import InfiniteScroll from "react-infinite-scroll-component";
import {useDispatch, useSelector} from "react-redux";
import {newMessageAction, thread, threadRemovedAction} from "../../actions"
import NotificationSound from "../../Assets/pristine-609.mp3"

const {Search} = Input;
const apiUrl = process.env.REACT_APP_API;

const ChatChildrenListComponent = ({conversationId}) => {
    const dispatch = useDispatch()
    const threadData = useSelector(globalState => globalState.threadData)
    const newMessage = useSelector(globalState => globalState.newMessage)
    const threadRemoved = useSelector(globalState => globalState.threadRemoved)

    const [state, setState] = useState({
        hasMore: true,
        listReady: false,
        loader: true,
        searchValue: '',
        pp: 50,
        list: [],
        listTotal: 0,
    })

    const [activeChatIndex, setActiveChatIndex] = useState(threadData.id)

    useEffect(() => {
        if (threadData.id !== activeChatIndex) {
            setActiveChatIndex(null)
        }
        return () => {
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [threadData])

    useEffect(() => {
        if (threadRemoved && threadRemoved.threadId) {
            removeFromList()
        }
        return () => {

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [threadRemoved])

    const removeFromList = () => {
        let {list} = state
        let itemIndex = list.indexOf(list.find(item => item.id === threadRemoved.threadId))

        if (itemIndex >= 0) {
            list[itemIndex]['deleted'] = true
        }
        // eslint-disable-next-line array-callback-return
        list.map((item, key) => {
            if (item.parentId === threadRemoved.threadId) {
                return list[key]['deleted'] = true
            }
        })

        setState(prevState => {
            return {
                ...prevState,
                list: list
            }
        })

        dispatch(threadRemovedAction({}))

        if (threadRemoved.threadId === threadData.id) {
            dispatch(thread({}))
        }
    }
    let flag = false

    const appendCallSound = () => {
        flag = true;
        let elementExists = document.getElementById("notification-sound");
        if (flag && !elementExists) {
            let audio = document.createElement('audio');
            audio.setAttribute('src', NotificationSound);
            audio.setAttribute('controls', 'controls');
            audio.setAttribute('type', 'audio/mpeg')
            audio.setAttribute('loop', 'true');
            audio.setAttribute('id', 'notification-sound');
            audio.setAttribute('autoplay', 'true');
            document.body.appendChild(audio);

            setTimeout(() => {
                audio.remove();
                flag = false;
            }, 1000)
        }
    }

    useEffect(() => {
        getList().then(res => {
            let {code, payload, message} = res

            if (code === 200) {

                setState(prevState => {
                    return {
                        ...prevState,
                        listReady: true,
                        listTotal: payload.count,
                        list: payload.list
                    }
                })


            } else {
                return errorMessage(message)
            }
        })
        return () => {
        }
    }, [])

    useEffect(() => {
        let {childThreadId} = newMessage

        let {list} = state
        if (newMessage.sender === 'mobile') {
            if (threadData.id !== childThreadId) {
                appendCallSound()
            }
            let element = document.getElementById(`children-list-item--${childThreadId}`);
            if (element) {
                if (list.length) {
                    let itemIndex = list.indexOf(list.find(item => item.id === childThreadId))
                    arrayMove(list, itemIndex, 0).then(res => {
                        if (!element.classList.contains('active')) {
                            res[0]['newMessage'] = true
                        }
                        setState(prevState => {
                            return {
                                ...prevState,
                                list: res
                            }
                        })

                    })
                }
            } else {
                let arr = newMessage
                arr['id'] = newMessage.childThreadId
                arr['parentId'] = newMessage.threadId
                arr['newMessage'] = true
                list.unshift(arr)
                setState(prevState => {
                    return {
                        ...prevState,
                        list,
                    }
                });
            }
        }

        return () => {
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newMessage])

    const arrayMove = async (arr, fromIndex, toIndex) => {
        let element = arr[fromIndex];
        arr.splice(fromIndex, 1);
        arr.splice(toIndex, 0, element);
        return arr
    }

    const getList = async (start = 0, end = 50, search = "") => {
        setState(prevState => {
            return {
                ...prevState,
                loader: true
            }
        })
        const queryParams = {
            _start: start,
            _end: end,
            search,
            type: 'children'
        }
        let res = await axios.get(`${apiUrl}/threads/list`, {
            params: queryParams,
            headers: authHeader()
        });

        const {data} = res;

        if (data.error === null) {
            const {count, rows} = data.payload
            setState(prevState => {
                return {
                    ...prevState,
                    loader: false
                }
            })

            return {
                code: 200,
                payload: {
                    count,
                    list: rows,
                }
            }
        } else {
            setState(prevState => {
                return {
                    ...prevState,
                    loader: false
                }
            })

            return {
                code: 400,
                message: data.error.message ? data.error.message : 'Something went wrong!'
            }
        }
    }

    const onSearch = value => {
        const {pp} = state
        setState(prevState => {
            return {
                ...prevState,
                searchValue: value
            }
        })
        getList(0, pp, value).then(res => {
            const {message, code, payload} = res
            if (code === 200) {
                setState(prevState => {
                    return {
                        ...prevState,
                        listTotal: payload.count,
                        list: payload.list
                    }
                })
            } else {
                return errorMessage(message)
            }
        })
    }

    const getMore = async () => {
        let {hasMore, list, pp, listTotal, searchValue} = state

        if (!hasMore) {
            return true
        }

        if (list.length === listTotal) {
            setState(prevState => {
                return {
                    ...prevState,
                    hasMore: false,
                }
            });
            return true
        }

        setState(prevState => {
            return {
                ...prevState,
                loader: true
            }
        });

        const start = list.length,
            end = parseInt(list.length) + pp;

        getList(start, end, searchValue).then(res => {
            const {message, code, payload} = res
            if (code === 200) {
                list = list.concat(payload.list)
                setState(prevState => {
                    return {
                        ...prevState,
                        loader: false,
                        list,
                    }
                })
            } else {
                return errorMessage(message)
            }
        })
    }

    const handleThreadData = data => {
        dispatch(thread(data))
        conversationId(data.id)
    }

    const {list, listReady, loader, hasMore} = state

    return (
        <div className="children-box">
            <div className="search-box">
                <div className="field-box">
                    <Search
                        autoComplete={new Date().valueOf()}
                        placeholder="Search"
                        onSearch={onSearch}
                    />
                </div>
            </div>
            <div id="childrenScrollableDiv" className="children-list-box messages-list-box"
                 style={{height: "100%", overflowY: "scroll"}}>
                {loader && (
                    <div id="Loader" className="loading-box">
                        <Spin size="large"/>
                    </div>
                )}
                {listReady && (
                    <>
                        <InfiniteScroll
                            dataLength={Object.keys(list).length}
                            next={getMore}
                            hasMore={hasMore}
                            loader={Object.keys(list).length ? (<Spin size="large"/>) : ''}
                            scrollableTarget="childrenScrollableDiv"
                        >
                            {list.map((item, key) => {
                                const {id, parentThreadName, deleted, userPhoneNumber, newMessage} = item
                                return (
                                    <div
                                        className={`children-list-item messages-list-item ${deleted ? 'deleted' : ''} ${activeChatIndex === id ? "active" : ""} ${newMessage && !deleted ? "unread" : ''}`}
                                        id={`children-list-item--${id}`}
                                        key={key}
                                        onClick={(e) => {
                                            let listItem = list[key]
                                            if (e.currentTarget.classList.contains('unread')) {
                                                dispatch(newMessageAction({}))
                                                e.currentTarget.classList.remove('unread')
                                                listItem["newMessage"] = false
                                            }
                                            if (id !== activeChatIndex) {
                                                setActiveChatIndex(id)
                                                item['type'] = "children"
                                                handleThreadData(item)
                                            }
                                        }}>
                                        <div className="users-phone-number">+{userPhoneNumber}</div>
                                        <div className="thread-name">{parentThreadName}</div>
                                    </div>
                                )
                            })}
                        </InfiniteScroll>
                        {!Object.keys(list).length && (
                            <div className="no-data">No Threads</div>
                        )}
                    </>
                )}
            </div>
        </div>
    )
}
export default ChatChildrenListComponent;
