import {setLocalStorage, clearLocalStorage} from "../_helpers";

export const signin = (userData) => {

    setLocalStorage("user", userData, true)
    return {
        type: 'SIGN_IN',
    }
}

export const signout = () => {

    clearLocalStorage()
    return {
        type: 'SIGN_OUT',
    }

}

export const thread = (data) => {
    return {
        type: 'NEW_THREAD',
        data
    }
}

export const newMessageAction = (data) => {
    return {
        type: 'NEW_MESSAGE',
        data
    }
}

export const threadRemovedAction = (data) => {
    return {
        type: 'REMOVE_THREAD',
        data
    }
}

export const newThreadAction = (data) => {
    return {
        type: 'NEW_THREAD_CREATED',
        data
    }
}

export const updateRemovedAction = (data) => {
    return {
        type: 'UPDATE_THREAD',
        data
    }
}
