/* eslint-disable no-template-curly-in-string */
import React from 'react';
import notification from 'antd/lib/notification'

export function internetStatus() {

    const wifiIcon = type => {
        switch (type) {
            case 'offline' : {
                return (
                    <svg className="icon-wifi" xmlns="http://www.w3.org/2000/svg" width="40" height="40"
                         viewBox="0 0 40 40">
                        <g transform="translate(4212 -4176)">
                            <rect width="40" height="40" transform="translate(-4212 4176)" fill="none"/>
                            <g transform="translate(-4208 4179.575)">
                                <path d="M20.316,28.158a2.283,2.283,0,1,1-2.283-2.283A2.283,2.283,0,0,1,20.316,28.158Z"
                                      transform="translate(-2.053 -1.905)"/>
                                <path
                                    d="M34.21,3.864,32.6,2.25,2.25,32.6,3.864,34.21,16.057,22.017a6.775,6.775,0,0,1,6.86,1.507l1.614-1.614a9.094,9.094,0,0,0-5.85-2.516l3.867-3.867a13.686,13.686,0,0,1,5.21,3.156l1.613-1.614a15.951,15.951,0,0,0-5.052-3.314l3.421-3.421A20.473,20.473,0,0,1,32.6,13.844L34.21,12.23v0a22.852,22.852,0,0,0-4.783-3.582Z"
                                    transform="translate(-2.25 -2.25)"/>
                                <path
                                    d="M16.654,14.747l2.331-2.331c-.276-.015-.547-.042-.824-.042a15.919,15.919,0,0,0-11.154,4.56l1.616,1.611a13.663,13.663,0,0,1,8.033-3.8Z"
                                    transform="translate(-2.181 -2.102)"/>
                                <path
                                    d="M18.23,7.908a20.4,20.4,0,0,1,4.83.6l1.875-1.874A22.776,22.776,0,0,0,2.25,12.176V12.2l1.6,1.6a20.454,20.454,0,0,1,14.378-5.9Z"
                                    transform="translate(-2.25 -2.201)"/>
                            </g>
                        </g>
                    </svg>
                )
            }
            case 'online' : {
                return (
                    <svg className="icon-wifi" xmlns="http://www.w3.org/2000/svg" width="40" height="40"
                         viewBox="0 0 40 40">
                        <g transform="translate(4257 -4176)">
                            <rect width="40" height="40" transform="translate(-4257 4176)" fill="none"/>
                            <g transform="translate(-4253 4183)">
                                <path d="M20.316,28.158a2.283,2.283,0,1,1-2.283-2.283A2.283,2.283,0,0,1,20.316,28.158Z"
                                      transform="translate(-2.053 -5.33)"/>
                                <path
                                    d="M11.779,21.674l1.614,1.613a6.813,6.813,0,0,1,9.386-.01l1.613-1.613a9.089,9.089,0,0,0-12.612.011Z"
                                    transform="translate(-2.111 -5.428)"/>
                                <path
                                    d="M7.008,16.933l1.614,1.613a13.645,13.645,0,0,1,19.066-.011L29.3,16.921a15.924,15.924,0,0,0-22.293.011Z"
                                    transform="translate(-2.181 -5.527)"/>
                                <path
                                    d="M34.21,12.178a22.76,22.76,0,0,0-31.96,0V12.2l1.6,1.6A20.47,20.47,0,0,1,32.595,13.8L34.21,12.18Z"
                                    transform="translate(-2.25 -5.625)"/>
                            </g>
                        </g>
                    </svg>
                )
            }
            default:
                break;
        }
    }

    const notificationHandler = (type, message, duration = null) => {
        notification.open({
            key: 'updatable',
            message,
            className: type,
            icon: wifiIcon(type),
            duration,
            closeIcon : (<div/>)
        });
    }

    window.addEventListener('online', () => notificationHandler('online', 'Your internet connection was restored.', 2.5));
    window.addEventListener('offline', () => notificationHandler('offline', 'You are currently offline.'));
}

