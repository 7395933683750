/* eslint-disable no-template-curly-in-string */
import React, {useEffect, useState} from 'react';
import Button from "antd/lib/button";
import {successMessage} from "../../_helpers";
import TemplateCreate from "./createTemplate";

const TemplateHeader = (props) => {
    const [state, setState] = useState({
        showCreateTemplate: false
    })

    useEffect(() => {

    })

    let {showCreateTemplate} = state

    return (
        <>
            <div className="template-box-header">
                <div className="title">
                    Answer templates
                </div>

                <div className="action-box">
                    <Button type="primary-bordered" onClick={() => {
                        setState(prevState => {
                            return {
                                ...prevState,
                                showCreateTemplate: true
                            }
                        })
                    }}>
                        Create Template
                    </Button>
                </div>
            </div>

            {showCreateTemplate && (
                <TemplateCreate visible={showCreateTemplate} handleSuccess={({flag, message}) => {
                    if (flag) {
                        props.handleCreateTemplate(new Date().getTime())
                        return successMessage(message)
                    }
                }} handleClose={flag => {
                    if (flag) {
                        setState(prevState => {
                            return {
                                ...prevState,
                                showCreateTemplate: false
                            }
                        })
                    }
                }}/>
            )}
        </>
    )
}

export default TemplateHeader;
