/* eslint-disable no-template-curly-in-string */
import React, {useEffect, useState} from 'react';
import TemplateTypeButton from "./templateTypes/button";
import TemplateTypeText from "./templateTypes/text";
import TemplateTypeImage from "./templateTypes/image";
import Button from "antd/lib/button";
import Spin from "antd/lib/spin";
import Modal from "antd/lib/modal";
import Radio from "antd/lib/radio"
import axios from "axios";
import {errorMessage, getLocalStorage} from "../../_helpers";
import Form from "antd/lib/form"

const apiUrl = process.env.REACT_APP_API;
const user = getLocalStorage("user", true)

const TemplateCreate = (props) => {
    const {visible} = props
    const [createTemplateForm] = Form.useForm()
    const [state, setState] = useState({
        showModal: false,
        error: false,
        templateType: 'text',
        text: {},
        button: {
            data: null,
            screenId: null,
            additionalData: null
        },
        image: {},
        components: {
            text: TemplateTypeText,
            button: TemplateTypeButton,
            image: TemplateTypeImage
        }
    })

    useEffect(() => {
        if (visible) {
            setState(prevState => {
                return {
                    ...prevState,
                    showModal: true
                }
            })

        }
        return () => {
        }
    }, [visible])

    const createTemplate = async () => {
        let {templateType, text, image, button} = state

        if (templateType === "button") {
            if (!button.screenId) {
                setState(prevState => {
                    return {
                        ...prevState,
                        error: true
                    }
                })
                return false
            }
            if (!button.data) {
                setState(prevState => {
                    return {
                        ...prevState,
                        error: true
                    }
                })
                return false
            }
        } else {
            if (!Object.keys(state[templateType]).length) {
                setState(prevState => {
                    return {
                        ...prevState,
                        error: true
                    }
                })
                return false
            }
        }

        setState(prevState => {
            return {
                ...prevState,
                loader: true
            }
        })

        const postData = new FormData();
        postData.append('type', templateType)

        if (templateType === "text") {
            postData.append('data', text.data)
        }
        if (templateType === "image") {
            postData.append('image', image.image)
        }
        if (templateType === "button") {
            postData.append('screenId', button.screenId)
            postData.append('data', button.data)
            postData.append('additionalData', button.additionalData)
        }


        let res = await axios.post(`${apiUrl}/templates/create/`, postData, {
            headers: {
                'content-type': 'multipart/form-data',
                'authorization': user.token
            }
        });

        let {data} = res

        if (data.error === null) {
            setState(prevState => {
                return {
                    ...prevState,
                    loader: false,
                    showModal: false
                }
            })

            props.handleSuccess({flag: true, message: "Template successfully created"})
        } else {
            setState(prevState => {
                return {
                    ...prevState,
                    loader: false
                }
            })

            return errorMessage(data.error.message)
        }
    }

    const handleData = data => {
        let {templateType, button} = state
        if (templateType === "button") {
            button['data'] = data.data
            button['screenId'] = data.screenId
            button['additionalData'] = data.additionalData
            setState(prevState => {
                return {
                    ...prevState,
                    error: false,
                    button: button
                }
            })
        } else {
            setState(prevState => {
                return {
                    ...prevState,
                    error: false,
                    [templateType]: data
                }
            })
        }

    }

    let {showModal, loader, templateType, error, components} = state

    let Component = components[templateType]

    return (
        <Modal
            centered
            closable={false}
            maskClosable={false}
            visible={showModal}
            className="ant-modal-create-template"
            afterClose={() => {
                props.handleClose(true);
            }}
            footer={
                [
                    <Button key="cancel" type="primary-bordered" onClick={() => {
                        setState(prevState => {
                            return {
                                ...prevState,
                                showModal: false
                            }
                        });
                    }}>
                        Cancel
                    </Button>,
                    <Button key="save" type="primary" onClick={() => {
                        createTemplate().then(res => {

                        })
                    }}>
                        Save
                    </Button>
                ]
            }
        >
            {
                loader ? (
                    <div id="Loader" className="loading-box">
                        <Spin size="large"/>
                    </div>
                ) : null
            }

            <div className="ant-modal-title">
                New Template
            </div>
            <div className="create-template-box">
                <Form initialValues={{templateType: "text"}} form={createTemplateForm} name="create-template-form">
                    <div className="field-row">
                        <Form.Item label="Type" name="templateType">
                            <Radio.Group name="type"
                                         onChange={(e) => {
                                             setState(prevState => {
                                                 return {
                                                     ...prevState,
                                                     templateType: e.target.value
                                                 }
                                             })
                                         }}>
                                <Radio value="text">Text</Radio>
                                <Radio value="button">App Link</Radio>
                                <Radio value="image">Image</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </div>
                </Form>

                {React.createElement(Component, {
                    handleData: handleData,
                    error: error
                })}
            </div>
        </Modal>
    )
}

export default TemplateCreate;
