/* eslint-disable no-template-curly-in-string */
import React, {useEffect, useState} from 'react';
import {ReactComponent as Logo} from "../../Assets/svg/logo.svg";
import Button from "antd/lib/button";
import ThreadCreate from "../thread/create";
import {successMessage} from "../../_helpers";
import Tabs from 'antd/lib/tabs';
import ChatChildrenListComponent from "./childrens";
import ChatParentListComponent from "./parents";

const {TabPane} = Tabs;

const Sidebar = ({conversationId}) => {

    const [state, setState] = useState({
        showCreateThread: false,
        tabKey: 'parent',
        deleteThreadData: null
    })

    useEffect(() => {
        if (document.getElementsByClassName('ant-tabs-ink-bar')[0]) {
            document.getElementsByClassName('ant-tabs-ink-bar')[0].remove()
        }
        return () => {
        }
    }, [])

    const tabChange = async key => {
        setState(prevState => {
            return {
                ...prevState,
                tabKey: key
            }
        })
    }

    const {showCreateThread, tabKey} = state

    return (

        <>
            <div className="global-sidebar">
                <div className="logo">
                    <Logo/>
                </div>

                <Tabs activeKey={tabKey} defaultActiveKey={tabKey} animated={false}
                      onChange={tabChange}>
                    <TabPane forceRender={true} className="pos-relative" tab="Sent" key="parent">
                        <ChatParentListComponent conversationId={(data) => {
                            conversationId(data)
                        }}/>
                    </TabPane>

                    <TabPane forceRender={true} className="pos-relative" tab="Active" key="children">
                        <ChatChildrenListComponent conversationId={(data) => {
                            conversationId(data)
                        }}/>
                    </TabPane>
                </Tabs>

                <div className="create-thread-button-wrap">
                    <Button type="primary" onClick={() => {

                        setState(prevState => {
                            return {
                                ...prevState,
                                showCreateThread: true
                            }
                        })
                    }}>
                        Create Thread
                    </Button>
                </div>
            </div>
            {showCreateThread && (
                <ThreadCreate visible={showCreateThread} handleSuccess={({flag, message}) => {
                    if (flag) {
                        return successMessage(message)
                    }
                }} handleClose={flag => {
                    if (flag) {
                        setState(prevState => {
                            return {
                                ...prevState,
                                showCreateThread: false
                            }
                        })
                    }
                }}/>
            )}
        </>
    )
}

export default Sidebar;
